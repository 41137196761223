import { useContext } from 'react';
import { TakeoffContext } from '../helper/Context';
import '../styles/SelectedTotals.css';
import { IconWindowMinimize, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../helper/UnitConversions';

export default function SelectedTotals({ }) {
    const {
        project,
        takeoffSettings,
        measurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement,
    } = useContext(TakeoffContext);

    let right = takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right' && document.getElementById('measurementsidebar')
        ? document.getElementById('measurementsidebar').offsetWidth * 1.01 + 5
        : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right' && document.getElementById('pagessidebar')
            ? document.getElementById('pagessidebar').offsetWidth * 1.01 + 5
            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right' && document.getElementById('aisidebar')
                ? document.getElementById('aisidebar').offsetWidth * 1.01 + 5
                : '5px'


    const get_length = (mID) => {
        let measurement = measurements[mID]
        let page = pages[measurement.page]

        if (page.scale) {
            return measurement.pitch
                ? (measurement.length / (Math.cos(Math.atan(measurement.pitch / 12))) * page.scale).toFixed(1)
                : (measurement.length * page.scale).toFixed(1)
        }
        return 'No scale'
    }

    const get_area = (mID) => {
        let measurement = measurements[mID]

        if (measurement.type === 'rectangle') {
            return get_rectangle_area(mID)
        } else if (measurement.type === 'circle') {
            return get_circle_area(mID)
        } else if (measurement.type === 'polygon') {
            return get_polygon_area(mID)
        } else if (measurement.type === 'line') {
            return get_line_area(mID)
        }
    }

    const get_circle_area = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * (pages[measurement.page]?.scale ** 2)).toFixed(1)
                : (measurement?.area * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_polygon_area = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * (pages[measurement.page]?.scale ** 2)).toFixed(1)
                : (measurement?.area * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_rectangle_area = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * (pages[measurement.page]?.scale ** 2)).toFixed(1)
                : (measurement?.area * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_line_area = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement.length / (Math.cos(Math.atan(measurement.pitch / 12))) * measurement.height * pages[measurement.page]?.scale).toFixed(1)
                : (measurement.length * measurement.height * pages[measurement.page]?.scale).toFixed(1)
        }
        return 'No scale'
    }

    const get_circle_volume = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * measurement.depth * (pages[measurement.page]?.scale ** 2)).toFixed(1)
                : (measurement?.area * measurement.depth * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_polygon_volume = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * (pages[measurement.page]?.scale ** 2) * measurement?.depth).toFixed(1)
                : (measurement?.area * measurement?.depth * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_rectangle_volume = (mID) => {
        const measurement = measurements[mID]
        const page = pages[measurement.page]

        if (page.scale) {
            return measurement?.pitch
                ? (measurement?.area / (Math.cos(Math.atan(measurement?.pitch / 12))) * measurement.depth * (pages[measurement.page]?.scale ** 2)).toFixed(1)
                : (measurement?.area * measurement.depth * (pages[measurement.page]?.scale ** 2)).toFixed(1)
        }
        return 'No scale'
    }

    const get_volume = (mID) => {
        const measurement = measurements[mID]

        if (measurement.type === 'rectangle') {
            return get_rectangle_volume(mID)
        } else if (measurement.type === 'circle') {
            return get_circle_volume(mID)
        } else if (measurement.type === 'polygon') {
            return get_polygon_volume(mID)
        }
    }

    const get_visible_uoms = () => {
        //loop through all measurements and add their uom to set
        let uoms = new Set()
        selectedMeasurements.forEach(mID => {
            if (measurements[mID]?.uom) {
                uoms.add(measurements[mID]?.uom)
            } else {
                uoms.add(UOM_Measured_Default[measurements[mID]?.type])
            }
        })

        return Array.from(uoms).sort((a, b) => UOM.indexOf(a) - UOM.indexOf(b))
    }

    const get_uom_total = (uom) => {
        let total = 0
        selectedMeasurements.forEach(mID => {
            if (measurements[mID]?.uom === uom || !measurements[mID]?.uom && UOM_Measured_Default[measurements[mID]?.type] === uom) {
                total += calculateValue(measurements[mID], pages[measurements[mID]?.page]?.scale)
            }
        })

        return total
    }

    if (!showSelectedTotal) {
        return (
            <>
                <div
                    className="selectedtotals-container-closed"
                    id='selectedtotals-closed'
                    style={{
                        right: right,
                        top: currentMeasurement && '85px',
                    }}
                    onClick={() => setShowSelectedTotal(true)}
                >
                    <IconWindowMinimize size={15} stroke={1} />
                </div>

                <Tooltip anchorSelect='#selectedtotals-closed' delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    Show selected totals
                </Tooltip>
            </>
        )
    }

    return (
        <div
            className="selectedtotals-container"
            style={{
                right: right,
                top: (currentMeasurement || selectedMeasurements.length > 1) && '85px',
            }}
        >
            <div className="selectedtotals-header">
                <div className="selectedtotals-title">
                    Selected totals
                </div>

                <div
                    className="selectedtotals-close"
                    id="selectedtotals-close"
                    onClick={() => setShowSelectedTotal(false)}
                >
                    <IconX size={15} stroke={1} />
                </div>

                <Tooltip anchorSelect='#selectedtotals-close' delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }} place='bottom'>
                    Close selected totals
                </Tooltip>
            </div>

            <div className="selectedtotals-content">
                {get_visible_uoms().map(uom =>
                    <div key={uom} className="selectedtotals-content-row">
                        <div className="selectedtotals-content-row-title">
                            {UOM_Display[uom]}
                        </div>
                        <div className="selectedtotals-content-row-value">
                            {get_uom_total(uom).toFixed(2)}
                        </div>
                    </div>
                )}

                {/*selectedMeasurements?.filter(m => measurements[m]?.type === 'count').length > 0 &&
                    <div className="selectedtotals-content-row">
                        <div className="selectedtotals-content-row-title">
                            Count
                        </div>
                        <div className="selectedtotals-content-row-value">
                            {selectedMeasurements?.filter(m => measurements[m]?.type === 'count').filter((m, i, a) => a.indexOf(m) === i).reduce((acc, m) => acc + measurements[m]?.count_dots?.length, 0)}
                        </div>
                    </div>
            */}

                {/*selectedMeasurements?.filter(m => measurements[m]?.type === 'line' && !measurements[m]?.height).length > 0 &&
                    <div className="selectedtotals-content-row">
                        <div className="selectedtotals-content-row-title">
                            Length
                        </div>
                        <div className="selectedtotals-content-row-value">
                            {selectedMeasurements?.filter(m => measurements[m]?.type === 'line' && !measurements[m]?.height)
                                .filter((m, i, a) => a.indexOf(m) === i).filter(m => pages[measurements[m].page].scale).length
                                === selectedMeasurements?.filter(m => measurements[m]?.type === 'line' && !measurements[m]?.height)
                                    .filter((m, i, a) => a.indexOf(m) === i).length
                                ? <>{selectedMeasurements?.filter(m => measurements[m]?.type === 'line' && !measurements[m]?.height)
                                    .filter((m, i, a) => a.indexOf(m) === i)
                                    .reduce((acc, m) => acc + parseFloat(get_length(m)), 0).toFixed(1)} ft</>
                                : 'No scale'
                            }
                        </div>
                    </div>
                        */}

                {/*selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && !measurements[m]?.depth
                    || measurements[m]?.type === 'circle' && !measurements[m]?.depth
                    || measurements[m]?.type === 'polygon' && !measurements[m]?.depth
                    || measurements[m]?.type === 'line' && measurements[m]?.height
                ).filter((m, i, a) => a.indexOf(m) === i).length > 0 &&
                    <div className="selectedtotals-content-row">
                        <div className="selectedtotals-content-row-title">
                            Area
                        </div>
                        <div className="selectedtotals-content-row-value">
                            {selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && !measurements[m]?.depth
                                || measurements[m]?.type === 'circle' && !measurements[m]?.depth
                                || measurements[m]?.type === 'polygon' && !measurements[m]?.depth
                                || measurements[m]?.type === 'line' && measurements[m]?.height
                            ).filter((m, i, a) => a.indexOf(m) === i).filter(m => pages[measurements[m].page].scale).length
                                === selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'circle' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'polygon' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'line' && measurements[m]?.height
                                ).filter((m, i, a) => a.indexOf(m) === i).length
                                ? <>{selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'circle' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'polygon' && !measurements[m]?.depth
                                    || measurements[m]?.type === 'line' && measurements[m]?.height
                                ).filter((m, i, a) => a.indexOf(m) === i).reduce((acc, m) => acc + parseFloat(get_area(m)), 0).toFixed(1)} ft<sup>2</sup></>
                                : 'No scale'
                            }
                        </div>
                    </div>
                        */}

                {/*selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && measurements[m]?.depth
                    || measurements[m]?.type === 'circle' && measurements[m]?.depth
                    || measurements[m]?.type === 'polygon' && measurements[m]?.depth
                ).filter((m, i, a) => a.indexOf(m) === i).length > 0 &&
                    <div className="selectedtotals-content-row">
                        <div className="selectedtotals-content-row-title">
                            Volume
                        </div>
                        <div className="selectedtotals-content-row-value">
                            {selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && measurements[m]?.depth
                                || measurements[m]?.type === 'circle' && measurements[m]?.depth
                                || measurements[m]?.type === 'polygon' && measurements[m]?.depth
                            ).filter((m, i, a) => a.indexOf(m) === i).filter(m => pages[measurements[m].page].scale).length
                                === selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && measurements[m]?.depth
                                    || measurements[m]?.type === 'circle' && measurements[m]?.depth
                                    || measurements[m]?.type === 'polygon' && measurements[m]?.depth
                                ).filter((m, i, a) => a.indexOf(m) === i).length
                                ? <>{selectedMeasurements?.filter(m => measurements[m]?.type === 'rectangle' && measurements[m]?.depth
                                    || measurements[m]?.type === 'circle' && measurements[m]?.depth
                                    || measurements[m]?.type === 'polygon' && measurements[m]?.depth
                                ).filter((m, i, a) => a.indexOf(m) === i).reduce((acc, m) => acc + parseFloat(get_volume(m)), 0).toFixed(1)} ft<sup>3</sup></>
                                : 'No scale'
                            }
                        </div>
                    </div>
                        */}
            </div>
        </div>
    )
}