import { useContext, useState } from 'react';
import { TakeoffContext } from '../helper/Context';
import '../styles/SelectedTotals.css';
import { IconChevronLeft, IconChevronRight, IconCircleArrowUpRight, IconCircles, IconSearch, IconTrashX, IconWindowMinimize, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../helper/UnitConversions';
import Popup from 'reactjs-popup';
import axios from 'axios';
import { API_ROUTE } from '../..';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/slices/authSlice';
import { Blocks } from 'react-loader-spinner';

export default function SelectedCalculatedTotals({ }) {
    const {
        project,
        takeoffSettings,
        measurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement,
        selectedCalculatedTotals,
    } = useContext(TakeoffContext);

    const [updating, setUpdating] = useState(false);

    let right = takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right' && document.getElementById('measurementsidebar')
        ? document.getElementById('measurementsidebar').offsetWidth * 1.01 + 5
        : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right' && document.getElementById('pagessidebar')
            ? document.getElementById('pagessidebar').offsetWidth * 1.01 + 5
            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right' && document.getElementById('aisidebar')
                ? document.getElementById('aisidebar').offsetWidth * 1.01 + 5
                : '5px'

    return (
        <>
            <div
                className="selectedtotals-container"
                style={{
                    right: right,
                    top: (currentMeasurement || selectedMeasurements.length > 1) && '85px',
                }}
            >
                <div className="selectedtotals-header">
                    <div className="selectedtotals-title">
                        Calculated selection
                    </div>
                </div>

                <div className="selectedtotals-content">
                    {selectedCalculatedTotals
                        ? <>
                            <div className="selectedtotals-content-row">
                                <div className="selectedtotals-content-row-title">
                                    ea
                                </div>
                                <div className="selectedtotals-content-row-value">
                                    {selectedCalculatedTotals?.count}
                                </div>
                            </div>
                            <div className="selectedtotals-content-row">
                                <div className="selectedtotals-content-row-title">
                                    ft
                                </div>
                                <div className="selectedtotals-content-row-value">
                                    {selectedCalculatedTotals?.length?.toFixed(2)}
                                </div>
                            </div>
                            <div className="selectedtotals-content-row">
                                <div className="selectedtotals-content-row-title">
                                    ft²
                                </div>
                                <div className="selectedtotals-content-row-value">
                                    {selectedCalculatedTotals?.area?.toFixed(2)}
                                </div>
                            </div>
                        </>
                        : <div>
                            Waiting for selection
                        </div>
                    }
                </div>

                {updating &&
                    <div className="selectedtotals-count-breakdown-container">
                        <div className="selectedtotals-count-breakdown-title">
                            Count breakdown
                        </div>

                        <div className="selectedtotals-updating">
                            <div>
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                        </div>
                    </div>
                }

                {selectedCalculatedTotals?.count && !updating &&
                    <div className="selectedtotals-count-breakdown-container">
                        <div className="selectedtotals-count-breakdown-title">
                            Count breakdown
                        </div>
                        <div>
                            {Object.keys(selectedCalculatedTotals?.count_breakdown || {}).map((item, index) =>
                                <CountBreakdownItem item={item} index={index} setUpdating={setUpdating} />
                            )}
                        </div>
                    </div>
                }
            </div>

        </>
    )
}

const CountBreakdownItem = ({ item, index, setUpdating }) => {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        project,
        takeoffSettings,
        measurements, setMeasurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement, setCurrentMeasurement,
        selectedCalculatedTotals, setSelectedCalculatedTotals,
        handleCountTransform,
    } = useContext(TakeoffContext);

    const [showModal, setShowModal] = useState(false);

    const [search, setSearch] = useState('');
    const [showGroupOptions, setShowGroupOptions] = useState(false);

    const changeCount = (measurementID) => {
        setUpdating(true);

        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-dot-count/`,
            'data': {
                'dot': selectedCalculatedTotals?.count_breakdown[item][0],
                'count': measurementID,
                'selectedDots': selectedCalculatedTotals?.count_breakdown[item],
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);

                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/calculate-selection/`,
                    data: {
                        'page': pageID,
                        'selection_polygon': selectedCalculatedTotals?.poly,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json"
                    },
                })
                    .then((calculate_response) => {
                        console.log(calculate_response);

                        setSelectedCalculatedTotals(prev => ({
                            ...prev,
                            'count_breakdown': calculate_response.data.count_breakdown,
                        }));
                        setUpdating(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div
            key={index}
            className="selectedtotals-count-breakdown-row"
            style={{
                backgroundColor: showModal && 'aliceblue',
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                setShowModal(true);
            }}
            onMouseEnter={() => {
                setCurrentMeasurement(item);
            }}
        >
            <div>
                <IconCircles
                    id={'measurement-icon-' + item + '-count'}
                    size={16} color={measurements[item]?.color} stroke={1.5}
                    style={{
                        outline: 'none',
                    }}
                />
            </div>

            <div className="selectedtotals-count-breakdown-row-title"
                id={`selectedtotals-count-breakdown-row-title-${item}`}
            >
                {measurements[item]?.name}
            </div>

            <Tooltip className='tooltip' anchorSelect={`#selectedtotals-count-breakdown-row-title-${item}`} delayShow={500} place={'bottom-end'}>
                {measurements[item]?.name}
            </Tooltip>

            <div className="selectedtotals-count-breakdown-row-value">
                {selectedCalculatedTotals?.count_breakdown[item].length}
            </div>

            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);
                }}
                position={'bottom left'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                style={{
                    width: '200px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    <div
                        className="pagessidebar-page-options-section-changecount pagessidebar-page-options-section-item"
                        onMouseEnter={() => setShowGroupOptions(true)}
                        onMouseLeave={() => setShowGroupOptions(false)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCircleArrowUpRight size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Change count
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            {takeoffSettings?.measurement_sidebar_location === 'left' ? <IconChevronRight size={15} stroke={1} /> : <IconChevronLeft size={15} stroke={1} />}
                        </div>

                        {showGroupOptions &&
                            <div
                                className={
                                    takeoffSettings?.measurement_sidebar_location === 'left'
                                        ? "pagessidebar-page-options-group-options"
                                        : "pagessidebar-page-options-group-options-right"
                                }
                            >
                                <div className="pagessidebar-page-options-group-search-container">
                                    <IconSearch size={15} stroke={1} />
                                    <input
                                        type='text'
                                        className='pagessidebar-page-options-group-search-input'
                                        placeholder='Search...'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>

                                <div className="pagessidebar-page-options-group-options-container">
                                    {measurements && Object.values(measurements).filter(m => m.type === 'count').filter(m => {
                                        if (search) {
                                            return m.name.toLowerCase().includes(search.toLowerCase());
                                        }
                                        return true;
                                    }).filter(m => String(m.page) === String(pageID)).map((m, index) =>
                                        <div
                                            key={m.id + 'count' + item}
                                            id={'pagessidebar-page-options-group-option' + m.id + 'count' + item}
                                            className="pagessidebar-page-options-group-option"
                                            onClick={() => {
                                                changeCount(m.id);
                                                setShowModal(false);
                                            }}
                                            style={{
                                                backgroundColor: m.id == item && 'aliceblue'
                                            }}
                                        >
                                            <div
                                                className={item == m.id
                                                    ? "pagessidebar-page-options-group-option-icon-selected"
                                                    : "pagessidebar-page-options-group-option-icon"
                                                }
                                            >
                                                &nbsp;
                                            </div>

                                            <div>
                                                <IconCircles
                                                    id={'measurement-icon-' + m.id + '-count'}
                                                    size={16} color={m?.color} stroke={1.5}
                                                    style={{
                                                        outline: 'none',
                                                    }}
                                                />
                                            </div>

                                            <div className="pagessidebar-page-options-group-option-text">
                                                {m.name}</div>

                                            <Tooltip className='tooltip' anchorSelect={'#pagessidebar-page-options-group-option' + m.id + 'count' + item} delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}>
                                                {m.id == item
                                                    ? <>Currently selected</>
                                                    : <>Change count to {m.name}</>}
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={(e) => {
                            const newMeasurement = {
                                ...measurements[item],
                                count_dots: measurements[item].count_dots.filter(d => !selectedCalculatedTotals?.count_breakdown[item].includes(d)),
                            }

                            handleCountTransform(newMeasurement, false, 'group-delete', null, null, selectedCalculatedTotals?.count_breakdown[item]);

                            setSelectedCalculatedTotals(prev => {
                                const newCountBreakdown = { ...prev.count_breakdown };
                                delete newCountBreakdown[item];

                                return {
                                    ...prev,
                                    count: prev.count - selectedCalculatedTotals?.count_breakdown[item].length,
                                    count_breakdown: newCountBreakdown,
                                }
                            })

                            e.stopPropagation();
                            e.preventDefault();
                            setShowModal(false);
                        }}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}