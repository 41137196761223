import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { toast } from "react-toastify";

export default function SAMHint({ projectUUID }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization,
        hintingSAM, setHintingSAM,
        SAMInferencing, setSAMInferencing,
    } = useContext(TakeoffContext);

    const [inference, setInference] = useState([]);

    const [addingNegative, setAddingNegative] = useState(false);

    const [hintID, setHintID] = useState(null);
    const [hintHistory, setHintHistory] = useState([]);
    const [hints, setHints] = useState([]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setHintingSAM(false);
                setHints([]);
                setInference([]);
            } else if (e.key === 'enter' || e.key === 'Enter' || e.key === 'Return' || e.key === 'return' && inference.length > 0 && !SAMInferencing) {
                addPolygon();
            } else if (e.key === 'tab' || e.key === 'Tab' && !SAMInferencing) {
                if (hintHistory.length > 0) {
                    let oldDot = hintHistory[hintHistory.length - 1];
                    let newHints = [...hints, oldDot];
                    setHints(newHints);
                    setHintHistory(prev => prev.slice(0, -1));

                    handleInferenceHints(newHints);
                }
            } else if (e.key === 'delete' || e.key === 'Delete' || e.key === 'backspace' || e.key === 'Backspace' && !SAMInferencing) {
                if (hints.length === 0) return;
                let mostRecentDot = hints[hints.length - 1];
                let newHints = hints.slice(0, -1);
                setHints(newHints);
                setHintHistory(prev => [...prev, mostRecentDot]);

                console.log(newHints);

                if (newHints.length > 0) {
                    handleInferenceHints(newHints);
                } else {
                    setInference([]);
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [inference, hints, hintHistory, SAMInferencing]);

    const addPolygon = () => {
        let poly = inference.map((point) => ({ x: point.y, y: point.x }));

        if (poly.length < 3) {
            return;
        }

        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'polygon',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'ai_created': true,
                ['polygon_dots']: [...poly]
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'dots': poly,
                'AI': true,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                createMeasurement(response.data, false, tempName, true);
            })
            .catch((error) => {
                console.log(error);
            });

        setInference([]);
        setHints([]);
    }

    const handleInferenceHints = (hints) => {
        if (SAMInferencing) return;

        console.log(hints);

        setSAMInferencing(true);

        /*
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/get-sam-inference/`,
            data: {
                'page': String(pageID),
                'positive_hints': hints?.filter((hint) => hint.type === 'positive')?.map((hint) => hint.point) || [],
                'negative_hints': hints?.filter((hint) => hint.type === 'negative')?.map((hint) => hint.point) || [],
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setInference(response.data.inference);
                setSAMInferencing(false);
            })
            .catch((error) => {
                console.log(error);
            });*/
    }

    const handleInference = (type, point) => {
        if (SAMInferencing) return;

        setSAMInferencing(true);

        let hintID_local = hintID;

        if (!hints.length) {
            console.log('new hint');
            hintID_local = uuidv4();
            setHintID(hintID_local);
        }

        let newHints = [...hints, {
            type: type,
            point: point,
        }];

        setHints(newHints);

        let toastId = toast.loading((`Auto filling...`), {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            theme: "light",
        });

        //curl -s -XPOST "https://bobyard.us-east-1.aws.modelbit.com/v1/get_onnx_sam_inference/latest" -d '{"data": [projectUUID, pageID, original_size, positive_locations, negative_locations]}' | json_pp

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/pattern-sam-inference/`,
            data: {
                'page': pageID,
                'hintID': hintID_local,
                'type': type === 'positive' ? 'pos' : 'neg',
                'x': point.x,
                'y': point.y,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setInference(response.data);
                setSAMInferencing(false);
                setAddingNegative(false);

                toast.update(toastId, {
                    render: `Auto filled!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.log(error);

                toast.update(toastId, {
                    render: `Error auto filling!`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            });

        /*axios({
            method: 'post',
            url: "https://bobyard.us-east-1.aws.modelbit.com/v1/get_pattern_sam_inference/latest",
            data: {
                'data': [projectUUID, pageID, [pages[pageID].sam_original_size_1, pages[pageID].sam_original_size_2], newHints?.filter((hint) => hint.type === 'positive')?.map((hint) => hint.point) || [], newHints?.filter((hint) => hint.type === 'negative')?.map((hint) => hint.point) || []]
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
                let temp = response.data.data.map((point) => ({ x: Math.max(0, Math.min(pages[pageID].height, point['x'])), y: Math.max(0, Math.min(pages[pageID].width, point['y'])) }));
                console.log(response.data.data.map((point) => ({ x: Math.max(0, Math.min(pages[pageID].height, point['x'])), y: Math.max(0, Math.min(pages[pageID].width, point['y'])) })))

                setInference(temp);
                setSAMInferencing(false);
                setAddingNegative(false);

                toast.update(toastId, {
                    render: `Auto filled!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.log(error);

                toast.update(toastId, {
                    render: `Error auto filling!`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            });*/

        /*
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/get-sam-inference/`,
            data: {
                'page': pageID,
                'positive_hints': newHints?.filter((hint) => hint.type === 'positive')?.map((hint) => hint.point) || [],
                'negative_hints': newHints?.filter((hint) => hint.type === 'negative')?.map((hint) => hint.point) || [],
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
    
                setInference(response.data.inference);
                setSAMInferencing(false);
                setAddingNegative(false);
            })
            .catch((error) => {
                console.log(error);
            });*/
    }

    return (
        <>
            {inference && inference.length > 0 &&
                <Line
                    points={inference.flatMap((point) => [point.y, point.x])}
                    stroke="blue"
                    //fill light blue, transparent
                    closed
                    fill="rgba(0, 0, 255, 0.1)"
                    strokeWidth={2 / pages[pageID].zoom}
                />
            }

            {hints?.filter((hint) => hint.type === 'positive')?.map((hint, index) => (
                <Circle
                    key={index}
                    x={hint.point.x}
                    y={hint.point.y}
                    radius={5 / pages[pageID].zoom}
                    fill="blue"
                />
            ))}

            {hints?.filter((hint) => hint.type === 'negative')?.map((hint, index) => (
                <Circle
                    key={index}
                    x={hint.point.x}
                    y={hint.point.y}
                    radius={5 / pages[pageID].zoom}
                    fill="red"
                />
            ))}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onContextMenu={(e) => {
                    e.evt.preventDefault();

                    if (hintingSAM && !SAMInferencing) {
                        setAddingNegative(true);
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        handleInference('negative', { x: x, y: y });
                    }
                }}
                onClick={(e) => {
                    if (hintingSAM && !SAMInferencing && !addingNegative) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        handleInference('positive', { x: x, y: y });
                    }
                }}
            /*onMouseOver={(e) => {
                if (hintingSAM && !SAMInferencing) {
                    e.target.getStage().container().style.cursor = "crosshair";
                } else if (hintingSAM && SAMInferencing) {
                    e.target.getStage().container().style.cursor = "progress";
                } else {
                    e.target.getStage().container().style.cursor = "default";
                }
            }}*/
            />
        </>
    );
}