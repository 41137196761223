import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import '../../styles/AIPopup.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconRobot } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';

export default function AISidebarArea({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        AILegends,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountExample, setAIAutoCountExample,
        handleTurnOffDrawing,
    } = useContext(TakeoffContext);

    const handleAutoMeasureRooms = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-measure-rooms/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrepSAM = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/prep-page-sam/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                if (response.data?.page?.sam_input_size_1) {
                    setPages(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'sam_input_size_1': response.data.page.sam_input_size_1,
                            'sam_input_size_2': response.data.page.sam_input_size_2,
                            'sam_original_size_1': response.data.page.sam_original_size_1,
                            'sam_original_size_2': response.data.page.sam_original_size_2,
                        }
                    }));

                    setAIMessages(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'samPrep': {
                                ...prev[pageID].samPrep,
                                'progress': 1,
                                'show': false,
                                'running': false,
                            }
                        },
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className='aisidebar-length-container'>
            {/*<div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Room areas
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {AIMessages?.[pageID]?.autoSegRooms?.running
                        ? <div className="navbar-aipopup-group-item">
                            Automatic
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <div
                            id='navbar-aipopup-item-auto-measure-rooms'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                setAIMessages(prev => ({
                                    ...prev,
                                    [pageID]: {
                                        ...prev[pageID],
                                        'autoSegRooms': {
                                            'progress': 1 / 100,
                                            'show': true,
                                            'running': true,
                                        }
                                    }
                                }));

                                handleAutoMeasureRooms();
                            }}
                        >
                            Automatic
                        </div>
                    }
                    <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-rooms'>
                        Automatically measure area of all rooms
                    </Tooltip>

                    {AIMessages?.[pageID]?.autoSegRoomsCrop?.running 
                        ? <div className="navbar-aipopup-group-item">
                            Crop
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : hintingAutoMeasure[pageID]?.show
                            ? <div
                                id='navbar-aipopup-item-auto-measure-rooms-hints-active'
                                className="navbar-aipopup-group-item-active"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                }}
                            >
                                Crop
                            </div>
                            : <div
                                id='navbar-aipopup-item-auto-measure-rooms-hints'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    setHintingAutoMeasure(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            show: true,
                                        }
                                    }))
                                }}
                            >
                                Crop
                            </div>
                    }

                    <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-rooms-hints'>
                        <div>Draw a rectangular crop to auto measure areas for</div>
                        <div>Click to turn off</div>
                    </Tooltip>

                    <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-rooms-hints'>
                        Draw a rectangular crop to auto measure areas for
                    </Tooltip>
                </div>
            </div>

            <hr className='navbar-aipopup-hr'></hr>*/}

            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Auto fill area
                    </div>
                </div>

                {hintingSAM
                    ? <div
                        id='navbar-aipopup-item-hint-sam-active'
                        className='navbar-aipopup-group-item-active'
                        onClick={() => {
                            handleTurnOffDrawing();
                        }}
                    >
                        Hint auto fill
                    </div>
                    : <div
                        id='navbar-aipopup-item-hint-sam'
                        className='navbar-aipopup-group-item'
                        onClick={() => {
                            handleTurnOffDrawing();

                            setHintingSAM(true);
                            setCurrentMeasurement(null);
                        }}
                    >
                        Hint auto fill
                    </div>}

                {/*pages[pageID]?.sam_input_size_1
                    ? <>
                        {hintingSAM
                            ? <div
                                id='navbar-aipopup-item-hint-sam-active'
                                className='navbar-aipopup-group-item-active'
                                onClick={() => {
                                    handleTurnOffDrawing();
                                }}
                            >
                                Hint auto fill
                            </div>
                            : <div
                                id='navbar-aipopup-item-hint-sam'
                                className='navbar-aipopup-group-item'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    setHintingSAM(true);
                                    setCurrentMeasurement(null);
                                }}
                            >
                                Hint auto fill
                            </div>
                        }

                        <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-hint-sam'>
                            <div>Place points to auto fill areas</div>
                            <div>Click to turn off</div>
                        </Tooltip>

                        <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-hint-sam'>
                            Place points to auto fill areas
                        </Tooltip>
                    </>
                    : <>
                        {AIMessages?.[pageID]?.samPrep?.running
                            ? <>
                                <div className='navbar-aipopup-group-item'>
                                    Prepping auto fill

                                    <Blocks
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#006AFE"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />
                                </div>
                            </>
                            : <>
                                <div
                                    id='navbar-aipopup-item-prep-sam'
                                    className='navbar-aipopup-group-item'
                                    onClick={() => {
                                        handlePrepSAM();
                                        setAIMessages(prev => ({
                                            ...prev,
                                            [pageID]: {
                                                ...prev[pageID],
                                                'samPrep': {
                                                    ...prev[pageID].samPrep,
                                                    'progress': 1 / 100,
                                                    'show': true,
                                                    'running': true,
                                                }
                                            },
                                        }));
                                    }}
                                >
                                    Prep page for auto fill
                                </div>

                                <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-prep-sam'>
                                    Prep AI for auto fill
                                </Tooltip>
                            </>
                        }
                    </>
                */}
            </div>
        </div>
    )
}