import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { IconArrowLeft, IconArrowRight, IconDownload, IconHome, IconSelect, IconTrashX, IconUpload } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import Popup from 'reactjs-popup';

export default function EstimateProjectsNavDropDown({ projectUUID }) {
    const auth = useSelector(selectAuth);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (auth.token) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'companyID': auth.contractor.company,
                }
            })
                .then((response) => {
                    console.log(response);

                    setProjects(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    return (
        <div className="projectdetails-projectlist-container" id="projectdetails-projectlist-container">
            <Tooltip delayShow={500} anchorSelect='#projectdetails-projectlist-button' place='top'>
                Navigate to another project
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id={"projectdetails-projectlist-button"}
                        className="estimate-projectlist-button"
                    >
                        <IconSelect size={20} stroke={1} color='#525252' />
                    </div>
                )}
                on='click'
                position="bottom center"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
            >
                <div className="navdropdown-items">
                    {projects.sort((a, b) => b.id - a.id).map(p =>
                        p.uuid === projectUUID
                            ? <div
                                key={p.id}
                                className="navdropdown-item-active"
                            >
                                <div>{p.title}</div>
                            </div>
                            : <a
                                key={p.id}
                                href={"/estimate/" + p.uuid}
                                className="navdropdown-item-link"
                            >
                                <div className="navdropdown-item">
                                    <div>{p.title}</div>
                                </div>
                            </a>
                    )}
                </div>
            </Popup>
        </div>
    )
}