import axios from 'axios';
import { lensPath, lensProp, over, mergeDeepLeft, F, isEmpty, isNil, pipe, map, when, is, ifElse, either, reject, toPairs, view, set, prop, includes, addIndex, reduce } from 'ramda';

const axiosCurry = (url) => (headers) => (method) => (data) =>
  axios({
    method,
    url,
    headers,
    data,
  });

const repsonseLens = lensProp('response');
const responseDataLens = lensProp('data');
const responseStatusLens = lensProp('status');
const eventValueLens = lensPath(['target', 'value']);
const eventCheckedLens = lensPath(['target', 'checked']);
const mergeFromLens = (lens) => (replacementObj) => over(lens)(mergeDeepLeft(replacementObj));
const removeNulls = pipe(map(when(is(Object), (v) => removeNulls(v))), reject(ifElse(is(String), F, either(isEmpty, isNil))));
const uploadFormData = (url) => (formData) => (onUploadProgress) => axios.post(url, formData, { onUploadProgress });
const formDataFromObj = (obj) => {
  const formData = new FormData();
  pipe(
    toPairs,
    map(([k, v]) => formData.append(k, v))
  )(obj);
  return formData;
};

const reverseView = (obj) => (lens) => view(lens)(obj);
const reverseSet = (obj) => (value) => (lens) => set(lens)(value)(obj);
const reverseOver = (obj) => (fn) => (lens) => over(lens)(fn)(obj);
const reverseProp = (obj) => (attr) => prop(attr)(obj);
const reverseIncludes = (list) => (val) => includes(val)(list);
const mapIndexed = addIndex(map);
const reduceIndexed = addIndex(reduce);

const getLastSundayTimestamp = () => {
  var now = new Date();
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
  return lastSunday.valueOf();
};
const WEEK_MS = 604800000; // number of milliseconds in a week
const HOUR_MS = 3600000; // number of milliseconds in an hour

export {
  axiosCurry,
  eventCheckedLens,
  eventValueLens,
  formDataFromObj,
  mapIndexed,
  mergeFromLens,
  reduceIndexed,
  removeNulls,
  repsonseLens,
  responseDataLens,
  responseStatusLens,
  reverseIncludes,
  reverseOver,
  reverseProp,
  reverseSet,
  reverseView,
  uploadFormData,
  getLastSundayTimestamp,
  WEEK_MS,
  HOUR_MS,
};
