import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { useStopwatch } from 'react-timer-hook';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

import { selectAuth } from '../redux/slices/authSlice';

import "./styles/Takeoff.css";

import { API_ROUTE, WEBSOCKET_ROUTE } from '../index';
import { TakeoffContext } from './helper/Context';

import TakeoffNavbar from './components/TakeoffNavbar';
import TakeoffToolbar from './TakeoffToolbar';
import TakeoffSidebar from './TakeoffSidebar';
import TakeoffPlan from './components/TakeoffPlan';

import RectangleConstructor from './components/measurements/RectangleConstructor';
import PolygonConstructor from './components/measurements/PolygonConstructor';
import LineConstructor from './components/measurements/LineConstructor';
import CountConstructor from './components/measurements/CountConstructor';
import ScaleConstructor from './components/measurements/ScaleConstructor';
import DeleteMeasurementModal from './components/sidebar/DeleteMeasurementModal';
import CircleConstructor from './components/measurements/CircleConstructor';
import RenderMeasurements from './components/measurements/RenderMeasurements';

import PolygonCutter from './components/measurements/PolygonCutter';
import RectangleCutter from './components/measurements/RectangleCutter';
import PolygonSpliter from './components/measurements/PolygonSpliter';
import RectangleSpliter from './components/measurements/RectangleSpliter';

import MiniMap from './MiniMap';
import handleKeyBind from './helper/KeyBinds';
import PolygonCutterRect from './components/measurements/PolygonCutterRect';
import RectangleCutterRect from './components/measurements/RectangleCutterRect';
import AICountRectangle from './components/ai/AICountRectangle';
import AICountPolygon from './components/ai/AICountPolygon';
import TakeoffLoading from './components/TakeoffLoading';
import AILocalizationDots from './components/ai/AILocalizationDots';
import AIAutoFind from './components/ai/AIAutoFind';
import SAMHint from './components/ai/SAMHint';
import ToolGuide from './components/ToolGuide';
import AIAutoMeasureRoomsRectangle from './components/ai/AIAutoMeasureRoomsRectangle';
import DeleteGroupModal from './components/sidebar/DeleteGroupModal';
import AIAutoMeasureRoomsPerimRectangle from './components/ai/AIAutoMeasureRoomsPerimRectangle';
import AIAutoFindDWRectangle from './components/ai/AIAutoFindDWRectangle';
import LegendsPopup from './components/ai/AILegend';
import AILegendSelector from './components/ai/AILegendSelector';
import AICountExample from './components/ai/AICountExample';
import AILegendConstructor from './components/ai/AILegendConstructor';
import AILegendEntry from './components/ai/AILegendEntry';
import CountEraser from './components/measurements/CountEraser';
import NotFoundPage from '../components/NotFoundPage';
import SelectedTotals from './components/SelectedTotals';
import DetailsNavbar from './components/detailsNavbar/DetailsNavbar';
import AIMeasureLengthRectangle from './components/ai/AIMeasureLengthRectangle';
import RenderAnnotations from './components/annotations/RenderAnnotations';
import AnnotationArrowConstructor from './components/annotations/AnnotationArrowConstructor';
import AnnotationRectangleConstructor from './components/annotations/AnnotationRectangleConstructor';
import AnnotationTextConstructor from './components/annotations/AnnotationTextConstructor';
import DeleteAnnotationModal from './components/sidebar/DeleteAnnotationModal';
import AILengthHint from './components/ai/AILengthHint';
import SelectionCalculator from './components/measurements/SelectionCalculator';
import SelectedCalculatedTotals from './components/SelectedCalculatedTotals';

const polygonClipping = require('polygon-clipping')

export default function Takeoff() {
    const params = useParams();
    const projectUUID = params.projectUUID;
    const pageIDIN = params.pageID;

    const auth = useSelector(selectAuth);

    const stageRef = useRef(null);

    const [project, setProject] = useState(null);
    const [pageID, setPageID] = useState(pageIDIN);
    const [pages, setPages] = useState(null);
    const [groups, setGroups] = useState(null);
    const [measurements, setMeasurements] = useState(null);
    const [settings, setSettings] = useState(null);
    const [tree, setTree] = useState(null);
    const [pageGroups, setPageGroups] = useState(null);
    const [ungroupedPages, setUngroupedPages] = useState(null);

    const [currentAnnotation, setCurrentAnnotation] = useState(null);
    const [selectedAnnotations, setSelectedAnnotations] = useState([]);
    const [annotations, setAnnotations] = useState(null);
    const [drawingAnnotationArrow, setDrawingAnnotationArrow] = useState(false);
    const [drawingAnnotationText, setDrawingAnnotationText] = useState(false);
    const [drawingAnnotationRectangle, setDrawingAnnotationRectangle] = useState(false);
    const [showDeleteAnnotationModal, setShowDeleteAnnotationModal] = useState(false);

    const [globalHide, setGlobalHide] = useState(false);

    const [search, setSearch] = useState('');

    const [currentDot, setCurrentDot] = useState(null);
    const [currentMeasurement, setCurrentMeasurement] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(null);

    const [selecting, setSelecting] = useState(false);
    const [selectedMeasurements, setSelectedMeasurements] = useState([]);
    const [selectedDots, setSelectedDots] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [multiselectAnchor1, setMultiselectAnchor1] = useState(null);
    const [multiselectAnchor2, setMultiselectAnchor2] = useState(null);

    const [previewURL, setPreviewURL] = useState(null);

    const [draggingPage, setDraggingPage] = useState(null);
    const [scrollingPage, setScrollingPage] = useState(null);

    const [drawingScale, setDrawingScale] = useState(false);
    const [drawingRectangle, setDrawingRectangle] = useState(false);
    const [drawingPolygon, setDrawingPolygon] = useState(false);
    const [drawingCount, setDrawingCount] = useState(false);
    const [drawingLine, setDrawingLine] = useState(false);
    const [drawingCircle, setDrawingCircle] = useState(false);

    const [addingCount, setAddingCount] = useState(false);
    const [deletingCount, setDeletingCount] = useState(false);
    const [erasingCount, setErasingCount] = useState(false);

    const [selectedCalculatedTotals, setSelectedCalculatedTotals] = useState(null);
    const [selectionCalculator, setSelectionCalculator] = useState(null);

    const [cuttingPolygonRect, setCuttingPolygonRect] = useState(false);
    const [cuttingRectangleRect, setCuttingRectangleRect] = useState(false);

    const [cuttingPolygon, setCuttingPolygon] = useState(false);
    const [cuttingRectangle, setCuttingRectangle] = useState(false);

    const [splittingPolygon, setSplittingPolygon] = useState(false);
    const [splittingRectangle, setSplittingRectangle] = useState(false);

    const [countDots, setCountDots] = useState([]);
    const [countHistory, setCountHistory] = useState([]);

    const [editingMeasurement, setEditingMeasurement] = useState(false);

    const [deleteGroup, setDeleteGroup] = useState(null);
    const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
    const [showDeleteMeasurementModal, setShowDeleteMeasurementModal] = useState(false);

    const [showMiniMap, setShowMiniMap] = useState(true);
    const [showSelectedTotal, setShowSelectedTotal] = useState(true);
    const [showDetails, setShowDetails] = useState(false);

    const [history, setHistory] = useState([]);
    const [currentHistory, setCurrentHistory] = useState(-1);
    const [changingHistory, setChangingHistory] = useState(false);

    const [keybinds, setKeybinds] = useState(null);
    const [shiftDown, setShiftDown] = useState(false);
    const [controlDown, setControlDown] = useState(false);
    const [optionDown, setOptionDown] = useState(false);
    const [tabDown, setTabDown] = useState(false);
    const [metaDown, setMetaDown] = useState(false);

    const [changingFilter, setChangingFilter] = useState(false);
    const [takeoffSettings, setTakeoffSettings] = useState(null);

    const [AILegendViews, setAILegendViews] = useState({});
    const [showLegendsPopup, setShowLegendsPopup] = useState(false);
    const [AILegends, setAILegends] = useState({});
    const [localizationLegends, setLocalizationLegends] = useState([]);
    const [selectingLegend, setSelectingLegend] = useState(false);
    const [drawingLegend, setDrawingLegend] = useState(false);
    const [drawingLegendEntry, setDrawingLegendEntry] = useState(false);
    const [drawingLegendEntryRect, setDrawingLegendEntryRect] = useState({});
    const [currentLegend, setCurrentLegend] = useState(null);
    const [populatingLegend, setPopulatingLegend] = useState({});

    const [autoCountReference, setAutoCountReference] = useState(null);
    const [autoCountLocation, setAutoCountLocation] = useState(null);
    const [AIAutoCountExample, setAIAutoCountExample] = useState({}); //pointer to localization symbol
    const [AIAutoCountRect, setAIAutoCountRect] = useState({});
    const [AIAutoCountPoly, setAIAutoCountPoly] = useState({});

    const [AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect] = useState({});

    const [hintingSAM, setHintingSAM] = useState(false);
    const [hintingAutoMeasure, setHintingAutoMeasure] = useState(false);
    const [hintingAutoMeasurePerim, setHintingAutoMeasurePerim] = useState(false);
    const [hintingAutoFindDW, setHintingAutoFindDW] = useState({});

    const [hintingLength, setHintingLength] = useState(false);
    const [inferencingLength, setInferencingLength] = useState(false);

    const [AIAutoClassifying, setAIAutoClassifying] = useState(false);
    const [AILocalization, setAILocalization] = useState(null);
    const [AIAutoFinding, setAIAutoFinding] = useState({});
    const [SAMInferencing, setSAMInferencing] = useState(false);

    const [autoNameAllPages, setAutoNameAllPages] = useState(false);
    const [autoNameAllPagesProgress, setAutoNameAllPagesProgress] = useState(0);

    const stopWatch = { ...useStopwatch() };

    const [AIMessages, setAIMessages] = useState(null);

    const AutoNameAllPagesToastId = useRef(null);

    useEffect(() => {
        if (!pageIDIN) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/pages/`,
                params: {
                    'projectUUID': projectUUID,
                    'included': true,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    if (response.data.length > 0) {
                        window.location.assign(`/takeoff/${projectUUID}/${response.data[0].id}`);
                    } else {
                        window.location.assign(`/files/${projectUUID}`);
                        window.alert('No pages included in project');
                        location.assign(`/files/${projectUUID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [auth, projectUUID, pageIDIN]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${WEBSOCKET_ROUTE}/takeoff-consumer/${projectUUID}/`,
        {
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000, // 1 minute, if no response is received, the connection will be closed
                interval: 25000, // every 25 seconds, a ping message will be sent
            },
            onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data);

                if (data.type === "count_dot_preview_update") {
                    const new_dot = data.new_dot;

                    setMeasurements(prev => {
                        if (!prev) {
                            return null;
                        }

                        if (!prev[new_dot.measurement]) {
                            return prev;
                        }

                        console.log(new_dot);

                        console.log({
                            ...prev,
                            [new_dot.measurement]: {
                                ...prev[new_dot.measurement],
                                count_dots: [...prev[new_dot.measurement].count_dots.map((dot) => {
                                    if (dot.id == new_dot.id) {
                                        return { ...new_dot };
                                    }
                                    return dot;
                                })],
                            },
                        })

                        return ({
                            ...prev,
                            [new_dot.measurement]: {
                                ...prev[new_dot.measurement],
                                count_dots: [...prev[new_dot.measurement].count_dots.map((dot) => {
                                    if (dot.id == new_dot.id) {
                                        return { ...new_dot };
                                    }
                                    return dot;
                                })],
                            },
                        });
                    });
                } else if (data.type === "page_prep_update") {
                    if (String(data.status) === "-1") {
                        setPages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'ai_prepped': true,
                            }
                        }));

                        if (String(data.page) === String(pageID)) {
                            axios({
                                method: 'get',
                                url: `${API_ROUTE}/api/page-localization/`,
                                params: {
                                    'page': data.page,
                                },
                                headers: {
                                    'Authorization': `Token ${auth.token}`,
                                    "Content-Type": "application/json"
                                },
                            })
                                .then((response) => {
                                    console.log(response);

                                    setAILocalization(response.data.localization_symbols);
                                    setLocalizationLegends(response.data.localization_legends);

                                    if (toast.isActive(AIMessages[data.page].prepLocalization.id)) {
                                        toast.update(AIMessages[data.page].prepLocalization.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].prepLocalization.id);
                                    } else {
                                        toast.success(`Prepping auto find on page ${pages[pageID].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'prepLocalization': {
                                                'message': '',
                                                'progress': 100,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].prepLocalization.id)) {
                                toast.update(AIMessages[data.page].prepLocalization.id, { progress: 1 });
                                toast.done(AIMessages[data.page].prepLocalization.id);
                            } else {
                                toast.success(`Prepping auto find on page ${pages[pageID].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'prepLocalization': {
                                        'message': '',
                                        'progress': 100,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else {
                        if (!AIMessages[data.page]?.prepLocalization?.id) {
                            let newID = uuidv4();
                            let message = (`Preparing localization on ${pages[data.page].title}`).length > 50 ? (`Preparing localization on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Preparing localization on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'prepLocalization': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else if (AIMessages[data.page]?.prepLocalization?.show && AIMessages[data.page]?.prepLocalization?.running) {
                            let progress = (Number(data.status) + AIMessages[data.page].prepLocalization.progress * 100) / 100;

                            if (progress >= 1) {
                                progress = 0.99;
                            }

                            toast.update(AIMessages[data.page].prepLocalization.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'prepLocalization': {
                                        ...prev[data.page].prepLocalization,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "auto_count_update") {
                    if (String(data.status) === "-1") {
                        if (!data?.measurements?.length > 0 && !data?.new_group) {
                            if (toast.isActive(AIMessages[data.page].autoCount.id)) {
                                toast.update(AIMessages[data.page].autoCount.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoCount.id);
                            }

                            toast.info(`Did not find any symbols on page ${pages[pageID].title}`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });

                            setAIAutoCountPoly(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[pageID],
                                    points: [],
                                    nextPoint: null,
                                    isComplete: false,
                                    show: false,
                                }
                            }));

                            setAIAutoCountRect(prev => ({
                                ...prev,
                                [data.page]: {
                                    start: null,
                                    end: null,
                                    show: false,
                                    isComplete: false,
                                }
                            }));

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoCount': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        } else {
                            if (String(data.page) === String(pageID) || project?.include_all_pages) {
                                axios({
                                    method: 'get',
                                    url: `${API_ROUTE}/api/ai-auto-count/`,
                                    params: {
                                        'page': data.page,
                                        'contractor': auth.contractor.id,
                                    },
                                    headers: {
                                        'Authorization': `Token ${auth.token}`,
                                        "Content-Type": "application/json",
                                    }
                                })
                                    .then((response) => {
                                        console.log(response);

                                        let temp_data = { 'page': data.page }

                                        if (data?.measurements?.length > 0) {
                                            temp_data['measurements'] = data.measurements;
                                        }

                                        if (data?.new_group) {
                                            temp_data['group'] = data.new_group;
                                        }

                                        axios({
                                            method: 'post',
                                            url: `${API_ROUTE}/api/create-ai-preview/`,
                                            data: temp_data,
                                            headers: {
                                                'Authorization': `Token ${auth.token}`,
                                                "Content-Type": "application/json",
                                            }
                                        })
                                            .then((response) => {
                                                console.log(response);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });

                                        axios({
                                            method: 'PUT',
                                            url: `${API_ROUTE}/api/takeoff-tree/`,
                                            data: {
                                                tree: response.data.tree,
                                                project: project.id,
                                                contractor: auth.contractor.id,
                                                page: pageID,
                                            },
                                            headers: {
                                                'Authorization': `Token ${auth.token}`,
                                                'Content-Type': 'application/json',
                                            }
                                        })
                                            .then(res => {
                                                console.log(res);

                                                setMeasurements(res.data.measurements);
                                                setGroups(res.data.groups);
                                                setTree(res.data.tree);

                                                setPages(prev => ({
                                                    ...prev,
                                                    [data.page]: {
                                                        ...prev[data.page],
                                                        'measurements': Object.values(res.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                                    }
                                                }));

                                                if (autoCountReference === 'auto') {
                                                    if (AIAutoCountRect[pageID]?.show) {
                                                        setAIAutoCountRect(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                start: null,
                                                                end: null,
                                                                isComplete: false,
                                                            }
                                                        }));
                                                    }

                                                    if (AIAutoCountPoly[pageID]?.show) {
                                                        setAIAutoCountPoly(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                points: [],
                                                                nextPoint: null,
                                                                isComplete: false,
                                                            }
                                                        }));
                                                    }

                                                    if (AIAutoCountExample[pageID]?.show) {
                                                        setAIAutoCountExample(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                symbol: null,
                                                            }
                                                        }));
                                                    }
                                                } else if (autoCountReference === 'example') {
                                                    if (AIAutoCountRect[pageID]?.show || AIAutoCountPoly[pageID]?.show || AIAutoCountExample[pageID]?.show) {
                                                        setAIAutoCountRect(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                start: null,
                                                                end: null,
                                                                show: false,
                                                                isComplete: false,
                                                            }
                                                        }));

                                                        setAIAutoCountPoly(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                points: [],
                                                                nextPoint: null,
                                                                isComplete: false,
                                                                show: false,
                                                            }
                                                        }));

                                                        setAIAutoCountExample(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                symbol: null,
                                                                show: true,
                                                            }
                                                        }));
                                                    }
                                                } else if (autoCountReference === 'legend') {
                                                    if (AIAutoCountRect[pageID]?.show || AIAutoCountPoly[pageID]?.show || AIAutoCountExample[pageID]?.show) {
                                                        setAIAutoCountRect(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                start: null,
                                                                end: null,
                                                                isComplete: false,
                                                            }
                                                        }));

                                                        setAIAutoCountPoly(prev => ({
                                                            ...prev,
                                                            [pageID]: {
                                                                ...prev[pageID],
                                                                points: [],
                                                                nextPoint: null,
                                                                isComplete: false,
                                                            }
                                                        }));
                                                    }
                                                }

                                                if (toast.isActive(AIMessages[data.page].autoCount.id)) {
                                                    toast.update(AIMessages[data.page].autoCount.id, { progress: 1 });
                                                    toast.done(AIMessages[data.page].autoCount.id);
                                                } else {
                                                    toast.success(`Auto counting page ${pages[pageID].title} done`, {
                                                        position: "top-right",
                                                        autoClose: 3000,
                                                        hideProgressBar: true,
                                                        closeOnClick: true,
                                                        pauseOnHover: false,
                                                        draggable: false,
                                                        progress: null,
                                                        theme: "light",
                                                    });
                                                }

                                                setAIMessages(prev => ({
                                                    ...prev,
                                                    [data.page]: {
                                                        ...prev[data.page],
                                                        'autoCount': {
                                                            'message': '',
                                                            'progress': 100,
                                                            'show': false,
                                                            'running': false,
                                                            'id': null,
                                                        }
                                                    }
                                                }));
                                            })
                                            .catch(err => {
                                                console.log(err);
                                            })

                                        setAILocalization(response.data.localization_symbols);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            } else {
                                if (toast.isActive(AIMessages[data.page].autoCount.id)) {
                                    toast.update(AIMessages[data.page].autoCount.id, { progress: 1 });
                                    toast.done(AIMessages[data.page].autoCount.id);
                                } else {
                                    toast.success(`Auto counting page ${pages[data.page].title} done`, {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: null,
                                        theme: "light",
                                    });
                                }

                                setAIMessages(prev => ({
                                    ...prev,
                                    [data.page]: {
                                        ...prev[data.page],
                                        'autoCount': {
                                            'message': '',
                                            'progress': 0,
                                            'show': false,
                                            'running': false,
                                            'id': null,
                                        }
                                    }
                                }));
                            }
                        }
                    } else {
                        if (!AIMessages?.[data.page]?.autoCount?.id) {
                            let newID = uuidv4();
                            let message = (`Auto counting page ${pages?.[data.page].title}`).length > 50 ? (`Auto counting page ${pages?.[data.page].title}`).substring(0, 50) + '...' : (`Auto counting page ${pages?.[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev?.[data.page],
                                    'autoCount': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else if (AIMessages?.[data.page]?.autoCount?.show && AIMessages?.[data.page]?.autoCount?.running) {
                            let progress = Math.min(Number(data.status), 99) / 100;

                            toast.update(AIMessages[data.page].autoCount.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoCount': {
                                        ...prev[data.page].autoCount,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "auto_find_update") {
                    if (Number(data.status) === -1) {
                        if (toast.isActive(AIMessages[data.page].autoFind.id)) {
                            toast.update(AIMessages[data.page].autoFind.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoFind.id);
                        } else {
                            toast.success(`Auto finding symbols on ${pages[data.page].title} done`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoFind': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoFind?.id) {
                            let newID = uuidv4();
                            let message = (`Auto finding symbols on ${pages[data.page].title}`).length > 50 ? (`Auto finding symbols on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto finding symbols on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFind': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoFind.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFind': {
                                        ...prev[data.page].autoFind,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "page_auto_name_update") {
                    if (data.status === "start") {
                        setAutoNameAllPagesProgress(0);

                        AutoNameAllPagesToastId.current = toast.info((`Auto naming`), {
                            position: "top-right",
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: 1 / 100,
                            theme: "light",
                        });
                    } else {
                        if (AutoNameAllPagesToastId.current === null) {
                            AutoNameAllPagesToastId.current = toast.info((`Auto naming`), {
                                position: "top-right",
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: (autoNameAllPagesProgress + 1) / Object.keys(pages).length,
                                theme: "light",
                            });
                        } else {
                            toast.update(AutoNameAllPagesToastId.current, { progress: (autoNameAllPagesProgress + 1) / Object.keys(pages).length });
                        }

                        if (autoNameAllPagesProgress + 1 === Object.keys(pages).length) {
                            setAutoNameAllPages(false);
                        }

                        setAutoNameAllPagesProgress(prev => prev + 1);

                        setPages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'title': data.name,
                            }
                        }));
                    }
                } else if (data.type === "sam_prep_update") {
                    if (Number(data.status) === -1) {
                        if (toast.isActive(AIMessages[data.page].samPrep.id)) {
                            toast.update(AIMessages[data.page].samPrep.id, { progress: 1 });
                            toast.done(AIMessages[data.page].samPrep.id);
                        } else {
                            toast.success(`Prepping auto fill on ${pages[data.page].title} done`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'samPrep': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));

                        setPages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'sam_input_size_1': data.sam_input_size_1,
                                'sam_input_size_2': data.sam_input_size_2,
                                'sam_original_size_1': data.sam_original_size_1,
                                'sam_original_size_2': data.sam_original_size_2,
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.samPrep?.id) {
                            let newID = uuidv4();
                            let message = (`Prepping auto fill on ${pages[data.page].title}`).length > 50 ? (`Prepping auto fill on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Prepping auto fill on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'samPrep': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].samPrep.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'samPrep': {
                                        ...prev[data.page].samPrep,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "sam_inference_update") {
                    if (Number(data.status) === -1) {
                        if (toast.isActive(AIMessages[data.page]?.samInference?.id)) {
                            toast.update(AIMessages[data.page].samInference.id, { progress: 1 });
                            toast.done(AIMessages[data.page].samInference.id);
                        } else {
                            toast.success((`Auto fill on ${pages[pageID].title}`).length > 50 ? (`Auto fill on ${pages[pageID].title}`).substring(0, 50) + '...' : (`Auto fill on ${pages[pageID].title}`), {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'samInference': {
                                    'message': '',
                                    'progress': 1,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.samPrep?.id) {
                            let newID = uuidv4();
                            let message = (`Auto filling on ${pages[data.page].title}`).length > 50 ? (`Auto filling on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto fill on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'samPrep': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].samPrep.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'samPrep': {
                                        ...prev[data.page].samPrep,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "auto_find_legend_update") {
                    if (String(data.status) === "-1") {
                        if (toast.isActive(AIMessages[data.page].autoFindLegend.id)) {
                            toast.update(AIMessages[data.page].autoFindLegend.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoFindLegend.id);
                        } else {
                            toast.success(`Auto finding legend on ${pages[data.page].title} done`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoFindLegend': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoFindLegend?.id) {
                            let newID = uuidv4();
                            let message = (`Auto finding legend on ${pages[data.page].title}`).length > 50 ? (`Auto finding legend on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto finding legend on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindLegend': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoFindLegend.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindLegend': {
                                        ...prev[data.page].autoFindLegend,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "room_segment_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-measure-rooms/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    if (toast.isActive(AIMessages[data.page].autoSegRooms.id)) {
                                        toast.update(AIMessages[data.page].autoSegRooms.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoSegRooms.id);
                                    } else {
                                        toast.success(`Measuring rooms on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRooms': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoSegRooms.id);
                                    toast.error('Error auto measuring rooms');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRooms': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoSegRooms.id)) {
                                toast.update(AIMessages[data.page].autoSegRooms.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoSegRooms.id);
                            } else {
                                toast.success(`Measuring rooms on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRooms': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else if (Number(data.status) === -2) {
                        if (toast.isActive(AIMessages[data.page].autoSegRooms.id)) {
                            toast.update(AIMessages[data.page].autoSegRooms.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoSegRooms.id);
                        }

                        toast.info(`No rooms found on ${pages[data.page].title}, try hinting a crop`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: null,
                            theme: "light",
                        });

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoSegRooms': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoSegRooms?.id) {
                            let newID = uuidv4();
                            let message = (`Auto measuring rooms on ${pages[data.page].title}`).length > 50 ? (`Auto measuring rooms on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto measuring rooms on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRooms': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoSegRooms.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRooms': {
                                        ...prev[data.page].autoSegRooms,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "room_segment_crop_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-measure-rooms-hints/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    if (toast.isActive(AIMessages[data.page].autoSegRoomsCrop.id)) {
                                        toast.update(AIMessages[data.page].autoSegRoomsCrop.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoSegRoomsCrop.id);
                                    } else {
                                        toast.success(`Measuring rooms on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoSegRoomsCrop.id);
                                    toast.error('Error auto measuring rooms');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoSegRoomsCrop.id)) {
                                toast.update(AIMessages[data.page].autoSegRoomsCrop.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoSegRoomsCrop.id);
                            } else {
                                toast.success(`Measuring rooms on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsCrop': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else {
                        if (!AIMessages[data.page]?.autoSegRoomsCrop?.id) {
                            let newID = uuidv4();
                            let message = (`Auto measuring rooms on ${pages[data.page].title}`).length > 50 ? (`Auto measuring rooms on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto measuring rooms on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsCrop': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoSegRoomsCrop.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsCrop': {
                                        ...prev[data.page].autoSegRoomsCrop,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "room_segment_perim_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-measure-room-perim/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    if (toast.isActive(AIMessages[data.page].autoSegRoomsPerim.id)) {
                                        toast.update(AIMessages[data.page].autoSegRoomsPerim.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoSegRoomsPerim.id);
                                    } else {
                                        toast.success(`Measuring room perims on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsPerim': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoSegRoomsPerim.id);
                                    toast.error('Error auto measuring room perims');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsPerim': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoSegRoomsPerim.id)) {
                                toast.update(AIMessages[data.page].autoSegRoomsPerim.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoSegRoomsPerim.id);
                            } else {
                                toast.success(`Measuring room perims on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerim': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else if (Number(data.status) === -2) {
                        if (toast.isActive(AIMessages[data.page].autoSegRoomsPerim.id)) {
                            toast.update(AIMessages[data.page].autoSegRoomsPerim.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoSegRoomsPerim.id);
                        }

                        toast.info(`No rooms found on ${pages[data.page].title}, try hinting a crop`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: null,
                            theme: "light",
                        });

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoSegRoomsPerim': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoSegRoomsPerim?.id) {
                            let newID = uuidv4();
                            let message = (`Auto measuring room perims on ${pages[data.page].title}`).length > 50 ? (`Auto measuring room perims on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto measuring room perims on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerim': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoSegRoomsPerim.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerim': {
                                        ...prev[data.page].autoSegRoomsPerim,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "room_segment_perim_crop_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-measure-room-perim-hint/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    if (toast.isActive(AIMessages[data.page].autoSegRoomsPerimCrop.id)) {
                                        toast.update(AIMessages[data.page].autoSegRoomsPerimCrop.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoSegRoomsPerimCrop.id);
                                    } else {
                                        toast.success(`Measuring room perims on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsPerimCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoSegRoomsPerimCrop.id);
                                    toast.error('Error auto measuring room perims');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoSegRoomsPerimCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoSegRoomsPerimCrop.id)) {
                                toast.update(AIMessages[data.page].autoSegRoomsPerimCrop.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoSegRoomsPerimCrop.id);
                            } else {
                                toast.success(`Measuring room perims on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerimCrop': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else if (Number(data.status) === -2) {
                        if (toast.isActive(AIMessages[data.page].autoSegRoomsPerimCrop.id)) {
                            toast.update(AIMessages[data.page].autoSegRoomsPerimCrop.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoSegRoomsPerimCrop.id);
                        }

                        toast.info(`No rooms found on ${pages[data.page].title}, try hinting a crop`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: null,
                            theme: "light",
                        });

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoSegRoomsPerimCrop': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoSegRoomsPerimCrop?.id) {
                            let newID = uuidv4();
                            let message = (`Auto measuring room perims on ${pages[data.page].title}`).length > 50 ? (`Auto measuring room perims on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto measuring room perims on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerimCrop': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoSegRoomsPerimCrop.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoSegRoomsPerimCrop': {
                                        ...prev[data.page].autoSegRoomsPerimCrop,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "auto_find_door_window_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-find-door-window/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    if (toast.isActive(AIMessages[data.page].autoFindDW.id)) {
                                        toast.update(AIMessages[data.page].autoFindDW.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoFindDW.id);
                                    } else {
                                        toast.success(`Auto finding doors & windows on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoFindDW': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoFindDW.id);
                                    toast.error('Error auto finding doors & windows');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoFindDW': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoFindDW.id)) {
                                toast.update(AIMessages[data.page].autoFindDW.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoFindDW.id);
                            } else {
                                toast.success(`Auto finding doors & windows on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDW': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else if (Number(data.status) === -2) {
                        if (toast.isActive(AIMessages[data.page].autoFindDW.id)) {
                            toast.update(AIMessages[data.page].autoFindDW.id, { progress: 1 });
                            toast.done(AIMessages[data.page].autoFindDW.id);
                        }

                        toast.info(`No doors & windows found on ${pages[data.page].title}, try hinting a crop`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: null,
                            theme: "light",
                        });

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'autoFindDW': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.autoFindDW?.id) {
                            let newID = uuidv4();
                            let message = (`Auto finding doors & windows on ${pages[data.page].title}`).length > 50 ? (`Auto finding doors & windows on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto finding doors & windows on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDW': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoFindDW.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDW': {
                                        ...prev[data.page].autoFindDW,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "auto_find_door_window_crop_update") {
                    if (Number(data.status) === -1) {
                        if (String(data.page) === String(pageID) || project?.include_all_pages) {
                            axios({
                                method: 'get',
                                'url': `${API_ROUTE}/api/auto-find-door-window-hint/`,
                                'params': {
                                    'page': data.page,
                                    'contractor': auth.contractor.id,
                                },
                                'headers': {
                                    'Authorization': `Token ${auth.token}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                                .then((response) => {
                                    console.log(response);

                                    setMeasurements(response.data.measurements);
                                    setGroups(response.data.groups);
                                    setTree(response.data.tree);

                                    setPages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                        }
                                    }));

                                    if (toast.isActive(AIMessages[data.page].autoFindDWCrop.id)) {
                                        toast.update(AIMessages[data.page].autoFindDWCrop.id, { progress: 1 });
                                        toast.done(AIMessages[data.page].autoFindDWCrop.id);
                                    } else {
                                        toast.success(`Auto finding doors & windows on ${pages[data.page].title} done`, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: null,
                                            theme: "light",
                                        });
                                    }

                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoFindDWCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    console.log(error);

                                    toast.done(AIMessages[data.page].autoFindDW.id);
                                    toast.error('Error auto finding doors & windows');
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [data.page]: {
                                            ...prev[data.page],
                                            'autoFindDWCrop': {
                                                'message': '',
                                                'progress': 0,
                                                'show': false,
                                                'running': false,
                                                'id': null,
                                            }
                                        }
                                    }));
                                });
                        } else {
                            if (toast.isActive(AIMessages[data.page].autoFindDWCrop.id)) {
                                toast.update(AIMessages[data.page].autoFindDWCrop.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoFindDWCrop.id);
                            } else {
                                toast.success(`Auto finding doors & windows on ${pages[data.page].title} done`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });
                            }

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDWCrop': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));
                        }
                    } else {
                        if (!AIMessages[data.page]?.autoFindDWCrop?.id) {
                            let newID = uuidv4();
                            let message = (`Auto finding doors & windows on ${pages[data.page].title}`).length > 50 ? (`Auto finding doors & windows on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto finding doors & windows on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDWCrop': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoFindDWCrop.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoFindDWCrop': {
                                        ...prev[data.page].autoFindDWCrop,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "count_dot_AI_example_preview_update" && measurements && data.measurement in measurements) {
                    setMeasurements(prev => ({
                        ...prev,
                        [data.measurement]: {
                            ...prev[data.measurement],
                            ai_count_example: {
                                ...prev[data.measurement].ai_count_example,
                                image: data.image,
                            }
                        },
                    }));
                } else if (data.type === "create_preview_done" && String(data.page) === String(pageID)) {
                    let temp_data = {
                        'page': data.page,
                    }

                    if (data.new_group) {
                        temp_data['group'] = data.new_group;
                    }

                    if (data.measurements) {
                        temp_data['measurements'] = data.measurements;
                    }

                    axios({
                        method: 'get',
                        url: `${API_ROUTE}/api/create-ai-preview/`,
                        params: temp_data,
                        headers: {
                            'Authorization': `Token ${auth.token}`,
                            "Content-Type": "application/json",
                        }
                    })
                        .then((response) => {
                            console.log(response);

                            setMeasurements(prev => {
                                let newMeasurements = { ...prev };

                                Object.entries(response.data).forEach(([key, value]) => {
                                    newMeasurements[key] = {
                                        ...newMeasurements[key],
                                        'count_dots': value
                                    };
                                });

                                return newMeasurements;
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (data.type === "create_preview_done_measurement" && String(data.page) === String(pageID)) {
                    axios({
                        method: 'get',
                        url: `${API_ROUTE}/api/create-ai-preview-measurement/`,
                        params: {
                            'measurement': data.measurement,
                        },
                        headers: {
                            'Authorization': `Token ${auth.token}`,
                            "Content-Type": "application/json",
                        }
                    })
                        .then((response) => {
                            console.log(response);

                            setMeasurements(prev => ({
                                ...prev,
                                [data.measurement]: {
                                    ...prev[data.measurement],
                                    'count_dots': response.data
                                }
                            }))
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (data.type === "populate_legend_update") {
                    if (String(data.status) === "-1") {
                        if (toast.isActive(populatingLegend.id)) {
                            toast.update(populatingLegend.id, { progress: 1 });
                            toast.done(populatingLegend.id);
                        } else {
                            toast.success(`Populated legend`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setPopulatingLegend(prev => ({
                            ...prev,
                            'message': '',
                            'progress': 0,
                            'show': false,
                            'running': false,
                            'id': null,
                        }))

                        setSelectingLegend(false);
                        setDrawingLegend(false);
                        setDrawingLegendEntryRect({
                            'start': null,
                            'end': null,
                        })
                        //setDrawingLegendEntry(false);
                        //setCurrentLegend(null);

                        setAILegendViews(prev => ({
                            ...prev,
                            [currentLegend]: 'view'
                        }));

                        axios({
                            method: 'get',
                            url: `${API_ROUTE}/api/populate-virtual-legend/`,
                            params: {
                                'virtual_legend': data.legend,
                            },
                            headers: {
                                'Authorization': `Token ${auth.token}`,
                                "Content-Type": "application/json",
                            },
                        })
                            .then((response) => {
                                console.log(response);

                                setAILegends(prev => ({
                                    ...prev,
                                    [response.data.id]: response.data,
                                }));

                                axios({
                                    method: 'post',
                                    url: `${API_ROUTE}/api/virtual-legend-preview/`,
                                    data: {
                                        'virtual_legend': response.data.id,
                                    },
                                    headers: {
                                        'Authorization': `Token ${auth.token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                    .then((previewResponse) => {
                                        console.log(previewResponse);

                                        setAILegends(prev => {
                                            let newEntries = [...prev[response.data.id].entries];

                                            newEntries.forEach((entry, index) => {
                                                newEntries[index] = {
                                                    ...entry,
                                                    file: previewResponse.data.entries.find(previewEntry => String(previewEntry.id) === String(entry.id)).file,
                                                }
                                            })

                                            return {
                                                ...prev,
                                                [response.data.id]: {
                                                    ...prev[response.data.id],
                                                    'entries': newEntries,
                                                }
                                            }
                                        })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    } else if (String(data.status) === "-2") {
                        if (toast.isActive(populatingLegend.id)) {
                            toast.update(populatingLegend.id, { progress: 1 });
                            toast.done(populatingLegend.id);
                        }

                        toast.info(`No symbols found in the region`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: null,
                            theme: "light",
                        })

                        setPopulatingLegend(prev => ({
                            ...prev,
                            'message': '',
                            'progress': 0,
                            'show': false,
                            'running': false,
                            'id': null,
                        }))

                        setSelectingLegend(false);
                        setDrawingLegend(false);
                        setDrawingLegendEntryRect({
                            'start': null,
                            'end': null,
                        })
                        //setDrawingLegendEntry(false);
                        //setCurrentLegend(null);

                        setAILegendViews(prev => ({
                            ...prev,
                            [currentLegend]: 'view'
                        }));
                    } else {
                        if (populatingLegend?.id === null) {
                            let newID = uuidv4();
                            let message = (`Populating legend`);

                            setPopulatingLegend(prev => ({
                                ...prev,
                                'message': message,
                                'progress': data.status ? (Number(data.status)) / 100 : 1 / 100,
                                'show': true,
                                'running': true,
                                'id': newID,
                            }));

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: data.status ? (Number(data.status)) / 100 : 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(populatingLegend.id, { progress });

                            setPopulatingLegend(prev => ({
                                ...prev,
                                'progress': progress
                            }));
                        }
                    }
                } else if (data.type === "populate_legend_entry_update") {
                    if (String(data.status) === "-1") {
                        if (toast.isActive(data.uuid)) {
                            toast.update(data.uuid, { progress: 1 });
                            toast.done(data.uuid);
                        } else {
                            toast.success(`Populated legend`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setDrawingLegend(false);
                        setDrawingLegendEntryRect({
                            'start': null,
                            'end': null,
                        })
                        //setDrawingLegendEntry(false);
                        //setCurrentLegend(null);

                        setAILegendViews(prev => ({
                            ...prev,
                            [currentLegend]: 'view'
                        }));

                        axios({
                            method: 'get',
                            url: `${API_ROUTE}/api/populate-virtual-legend/`,
                            params: {
                                'virtual_legend': data.legend,
                            },
                            headers: {
                                'Authorization': `Token ${auth.token}`,
                                "Content-Type": "application/json",
                            },
                        })
                            .then((response) => {
                                console.log(response);

                                setAILegends(prev => ({
                                    ...prev,
                                    [response.data.id]: response.data,
                                }));
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    } else {
                        if (!toast.isActive(data.uuid)) {
                            let message = (`Populating legend`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: data.status ? (Number(data.status)) / 100 : 1 / 100,
                                theme: "light",
                                toastId: data.uuid,
                            });
                        } else {
                            let progress = (Number(data.status)) / 100;

                            toast.update(data.uuid, { progress });
                        }
                    }
                } else if (data.type === "auto_measure_length_update") {
                    if (Number(data.status) === -1) {
                        if (!data?.new_group) {
                            if (toast.isActive(AIMessages[data.page].autoMeasureLength.id)) {
                                toast.update(AIMessages[data.page].autoMeasureLength.id, { progress: 1 });
                                toast.done(AIMessages[data.page].autoMeasureLength.id);
                            }

                            toast.info(`No lines found on ${pages[data.page].title}`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoMeasureLength': {
                                        'message': '',
                                        'progress': 0,
                                        'show': false,
                                        'running': false,
                                        'id': null,
                                    }
                                }
                            }));

                            setAIAutoMeasureLengthRect(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'start': null,
                                    'end': null,
                                    'is_complete': false,
                                }
                            }));
                        } else {
                            if (String(data.page) === String(pageID) || project?.include_all_pages) {
                                axios({
                                    method: 'get',
                                    'url': `${API_ROUTE}/api/create-pipe-lengths/`,
                                    'params': {
                                        'page': data.page,
                                    },
                                    'headers': {
                                        'Authorization': `Token ${auth.token}`,
                                        'Content-Type': 'application/json',
                                    }
                                })
                                    .then((response) => {
                                        console.log(response);

                                        setMeasurements(response.data.measurements);
                                        setGroups(response.data.groups);
                                        setTree(response.data.tree);

                                        setPages(prev => ({
                                            ...prev,
                                            [data.page]: {
                                                ...prev[data.page],
                                                'measurements': Object.values(response.data.measurements).filter(measurement => String(measurement.page) === String(data.page)).length,
                                            }
                                        }));

                                        if (toast.isActive(AIMessages[data.page].autoMeasureLength.id)) {
                                            toast.update(AIMessages[data.page].autoMeasureLength.id, { progress: 1 });
                                            toast.done(AIMessages[data.page].autoMeasureLength.id);
                                        } else {
                                            toast.success(`Auto measuring lines on ${pages[data.page].title} done`, {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: null,
                                                theme: "light",
                                            });
                                        }

                                        setAIMessages(prev => ({
                                            ...prev,
                                            [data.page]: {
                                                ...prev[data.page],
                                                'autoMeasureLength': {
                                                    'message': '',
                                                    'progress': 0,
                                                    'show': false,
                                                    'running': false,
                                                    'id': null,
                                                }
                                            }
                                        }));

                                        setAIAutoMeasureLengthRect(prev => ({
                                            ...prev,
                                            [data.page]: {
                                                ...prev[data.page],
                                                'start': null,
                                                'end': null,
                                                'is_complete': false,
                                            }
                                        }));
                                    })
                                    .catch((error) => {
                                        console.log(error);

                                        toast.done(AIMessages[data.page].autoMeasureLength.id);
                                        toast.error('Error auto measuring lines');
                                        setAIMessages(prev => ({
                                            ...prev,
                                            [data.page]: {
                                                ...prev[data.page],
                                                'autoMeasureLength': {
                                                    'message': '',
                                                    'progress': 0,
                                                    'show': false,
                                                    'running': false,
                                                    'id': null,
                                                }
                                            }
                                        }));
                                    });
                            } else {
                                if (toast.isActive(AIMessages[data.page].autoMeasureLength.id)) {
                                    toast.update(AIMessages[data.page].autoMeasureLength.id, { progress: 1 });
                                    toast.done(AIMessages[data.page].autoMeasureLength.id);
                                } else {
                                    toast.success(`Auto measuring lines on ${pages[data.page].title} done`, {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: null,
                                        theme: "light",
                                    });
                                }

                                setAIMessages(prev => ({
                                    ...prev,
                                    [data.page]: {
                                        ...prev[data.page],
                                        'autoMeasureLength': {
                                            'message': '',
                                            'progress': 0,
                                            'show': false,
                                            'running': false,
                                            'id': null,
                                        }
                                    }
                                }));

                                setAIAutoMeasureLengthRect(prev => ({
                                    ...prev,
                                    [data.page]: {
                                        'start': null,
                                        'end': null,
                                        'is_complete': false,
                                        'show': false,
                                    }
                                }));
                            }
                        }
                    } else {
                        if (!AIMessages[data.page]?.autoMeasureLength?.id) {
                            let newID = uuidv4();
                            let message = (`Auto measuring lines on ${pages[data.page].title}`).length > 50 ? (`Auto measuring lines on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Auto measuring lines on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoMeasureLength': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else if (data.status) {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].autoMeasureLength.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'autoMeasureLength': {
                                        ...prev[data.page].autoMeasureLength,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "prep_length_update") {
                    console.log(data.status);
                    if (String(data.status) === "-1") {
                        console.log('done prepping');
                        setPages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'ai_prepped_length': true,
                            }
                        }));

                        if (toast.isActive(AIMessages[data.page].prepLength.id)) {
                            toast.update(AIMessages[data.page].prepLength.id, { progress: 1 });
                            toast.done(AIMessages[data.page].prepLength.id);
                        } else {
                            toast.success(`Prepped length on ${pages[data.page].title} done`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }

                        setAIMessages(prev => ({
                            ...prev,
                            [data.page]: {
                                ...prev[data.page],
                                'prepLength': {
                                    'message': '',
                                    'progress': 0,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                }
                            }
                        }));
                    } else {
                        if (!AIMessages[data.page]?.prepLength?.id) {
                            let newID = uuidv4();
                            let message = (`Prepping length on ${pages[data.page].title}`).length > 50 ? (`Prepping length on ${pages[data.page].title}`).substring(0, 50) + '...' : (`Prepping length on ${pages[data.page].title}`);

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'prepLength': {
                                        'message': message,
                                        'progress': 1 / 100,
                                        'show': true,
                                        'running': true,
                                        'id': newID,
                                    }
                                }
                            }));
                        } else if (AIMessages[data.page]?.prepLength?.show && data.status) {
                            let progress = (Number(data.status)) / 100;

                            toast.update(AIMessages[data.page].prepLength.id, { progress });

                            setAIMessages(prev => ({
                                ...prev,
                                [data.page]: {
                                    ...prev[data.page],
                                    'prepLength': {
                                        ...prev[data.page].prepLength,
                                        'progress': progress,
                                    }
                                }
                            }));
                        }
                    }
                } else if (data.type === "virtual_legend_preview_update" && data.legend in AILegends) {
                    let found_index = AILegends[data.legend].entries.findIndex((symbol) => symbol.id === data.entry);

                    if (found_index !== -1) {
                        setAILegends(prev => ({
                            ...prev,
                            [data.legend]: {
                                ...prev[data.legend],
                                entries: [
                                    ...prev[data.legend]['entries'].slice(0, found_index),
                                    {
                                        ...prev[data.legend]['entries'][found_index],
                                        'file': data.image,
                                    },
                                    ...prev[data.legend]['entries'].slice(found_index + 1),
                                ]
                            }
                        }));

                        console.log('found', AILegends[data.legend].entries[found_index]);
                    }
                }
            },
            onClose: (e) => {
                console.log(e);
            },
            shouldReconnect: (closeEvent) => true,
            onOpen: (e) => {
                console.log(e);
            },
        },
    );

    useEffect(() => {
        if (auth.token && projectUUID && pageID && !project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/takeoff/`,
                params: {
                    'projectUUID': projectUUID,
                    'pageID': pageID,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setProject(response.data.project);

                    setPages(response.data.pages);
                    setPageGroups(response.data.page_groups);
                    setUngroupedPages(response.data.ungrouped_pages);

                    setAnnotations(response.data.annotations);

                    let messages = {};
                    response.data.pages && Object.values(response.data.pages).forEach((page) => {
                        messages[page.id] = {
                            'prepLocalization': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoFind': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoCount': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'samPrep': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoFindLegend': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoNamePage': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoNameAllPages': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoSegRooms': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoSegRoomsCrop': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoSegRoomsPerim': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoSegRoomsPerimCrop': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoFindDW': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoFindDWCrop': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'autoMeasureLength': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                            'prepLength': {
                                'message': '',
                                'progress': 0,
                                'show': false,
                                'running': false,
                                'id': null,
                            },
                        };
                    });
                    setAIMessages(messages);

                    setAILegends(response.data.legends);

                    if (response.data.legends && Object.keys(response.data.legends).length > 0) {
                        setCurrentLegend(Object.keys(response.data.legends)[0]);
                    }
                    setLocalizationLegends(response.data.localization_legends);

                    setTree(response.data.tree);

                    setGroups(response.data.groups);
                    setMeasurements(response.data.measurements);

                    setKeybinds(response.data.keybind);
                    setSettings(response.data.settings);

                    setTakeoffSettings(response.data.takeoff_settings);

                    setAILocalization(response.data.localization_symbols);
                    if (!response.data.page?.position_x || !response.data.page?.position_y || !response.data.page?.zoom) {
                        handleResetZoom(response.data.pages[pageID]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, projectUUID, pageID, project]);

    const onWindowClick = useCallback((e) => {
        if (!e.defaultPrevented) {
            setSelectedMeasurements([]);
        }
    }, [])

    const undoAdd = (measurement) => {
        let tempMeasurement = Object.values(measurements).find((m) =>
            compareTwoArrays(m.polygon_dots, measurement.polygon_dots)
            && compareTwoSubpolygons(m.sub_polygons ? m.sub_polygons : {}, measurement.sub_polygons ? measurement.sub_polygons : {})
            && m.page === measurement.page
            && m.group === measurement.group
            && m.type === measurement.type
            && m.number === measurement.number
            && m.name === measurement.name
            && m.depth === measurement.depth
            && m.height === measurement.height
            && m.date_created === measurement.date_created
        );

        if (tempMeasurement) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/redo-delete-measurement/`,
                data: {
                    "measurement": tempMeasurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    if (currentMeasurement === tempMeasurement.id) {
                        setCurrentMeasurement(null);
                    }

                    if (selectedMeasurements.find((a) => a === tempMeasurement.id)) {
                        setSelectedMeasurements(prev => prev.filter((a) => a !== tempMeasurement.id));
                    }

                    setMeasurements(response.data.measurements);
                    //setGroups(response.data.groups);
                    setTree(response.data.tree);

                    setChangingHistory(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const undoDelete = (measurement) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/undo-delete-measurement/`,
            data: measurement,
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                setMeasurements(response.data.measurements)
                //setGroups(response.data.groups)
                setTree(response.data.tree)
                setCurrentMeasurement(response.data.measurement.id);
                setChangingHistory(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const undoEdit = (measurement, tofind) => {
        let tempMeasurement = measurements[tofind.id];

        if (tempMeasurement) {
            let url = '';

            if (measurement.type === 'polygon') {
                url = `${API_ROUTE}/api/polygon/`;
            } else if (measurement.type === 'line') {
                url = `${API_ROUTE}/api/line/`;
            } else if (measurement.type === 'count') {
                url = `${API_ROUTE}/api/count/`;
            } else if (measurement.type === 'rectangle') {
                url = `${API_ROUTE}/api/rectangle/`;
            } else if (measurement.type === 'circle') {
                url = `${API_ROUTE}/api/circle/`;
            }

            console.log(measurement);

            axios({
                method: 'put',
                url: url,
                data: {
                    ...measurement,
                    'userID': auth.user.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    updateMeasurementInState(response.data);

                    setChangingHistory(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const redoDelete = (measurement) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/redo-delete-measurement/`,
            data: {
                "projectID": project.id,
                "measurementIndex": measurement.index,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                if (currentMeasurement === response.data.deleted_measurement_id) {
                    setCurrentMeasurement(null);
                }

                if (selectedMeasurements.find((a) => a === response.data.deleted_measurement_id)) {
                    setSelectedMeasurements(prev => prev.filter((a) => a !== response.data.deleted_measurement_id));
                }

                setMeasurements(response.data.measurements);
                setGroups(response.data.groups);
                setTree(response.data.tree);

                setChangingHistory(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUndo = () => {
        console.log('undo');
        console.log(history);
        console.log(currentHistory);

        if (currentHistory === -1 || currentHistory >= history.length) {
            return;
        }

        setChangingHistory(true);

        if (history[currentHistory].action === 'add') {
            undoAdd(history[currentHistory].current);
        } else if (history[currentHistory].action === 'delete') {
            undoDelete(history[currentHistory].previous);
        } else if (history[currentHistory].action === 'edit') {
            undoEdit(history[currentHistory].previous, history[currentHistory].current);
        }

        setCurrentHistory(currentHistory - 1);
    }

    const handleRedo = () => {
        console.log('redo');

        if (currentHistory === history.length - 1 || currentHistory >= history.length) {
            return;
        }

        setChangingHistory(true);

        if (history[currentHistory + 1].action === 'add') {
            undoDelete(history[currentHistory + 1].current);
        } else if (history[currentHistory + 1].action === 'delete') {
            redoDelete(history[currentHistory + 1].previous);
        } else if (history[currentHistory + 1].action === 'edit') {
            undoEdit(history[currentHistory + 1].current, history[currentHistory + 1].previous);
        }

        setCurrentHistory(currentHistory + 1);
        //setChangingHistory(false);
    }

    const moveCurrentMeasurementUp = () => {
        let remainingMeasurements = Object.values(measurements).filter((m) => m.index < measurements[currentMeasurement].index);

        return remainingMeasurements.length > 0
            ? remainingMeasurements.sort((a, b) => b.index - a.index)[0].id
            : null;
    }

    const moveCurrentMeasurementDown = () => {
        let remainingMeasurements = Object.values(measurements).filter((m) => m.index > measurements[currentMeasurement].index)

        return remainingMeasurements.length > 0
            ? remainingMeasurements.sort((a, b) => a.index - b.index)[0].id
            : null;
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!selecting && e.keyCode === 16) {
                setSelecting(true);
            }

            if (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && !changingHistory) {
                e.preventDefault();

                if (e.shiftKey) {
                    handleRedo();
                } else {
                    handleUndo();
                }
            } else if (e.key === 'Escape') {
                if (showDeleteMeasurementModal) {
                    setShowDeleteMeasurementModal(false);
                }

                if (document.activeElement.tagName !== 'INPUT') {
                    //e.target.style.cursor = 'default';

                    setDrawingScale(false);
                    setDrawingRectangle(false);
                    setDrawingPolygon(false);
                    setDrawingCount(false);
                    setDrawingLine(false);
                    setDrawingCircle(false);

                    setAddingCount(false);
                    setDeletingCount(false);
                    setErasingCount(false);
                    setSelectionCalculator(false);
                    setSelectedCalculatedTotals(null);
                    setCuttingPolygon(false);
                    setCuttingRectangle(false);
                    setCuttingPolygonRect(false);
                    setCuttingRectangleRect(false);
                    setSplittingPolygon(false);
                    setSplittingRectangle(false);
                    setCuttingPolygonRect(false);
                    setCuttingRectangleRect(false);

                    setDrawingAnnotationArrow(false);
                    setDrawingAnnotationText(false);
                    setDrawingAnnotationRectangle(false);

                    if (!currentMeasurement) {
                        setCurrentGroup(null);
                    }

                    if (drawingCount) {
                        setDrawingCount(false);

                        if (countDots.length > 0) {
                            addCount();
                        }
                    } else if (drawingCount) {
                        setDrawingCount(false);
                    }

                    setSelectedMeasurements([]);
                    setSelectedGroups([]);
                    setCurrentMeasurement(null);
                    setCurrentDot(null);
                    setSelectedDots([]);

                    setCurrentAnnotation(null);
                    setSelectedAnnotations([]);

                    setAIAutoClassifying(false);
                    setAIAutoCountRect(prev => {
                        let newRect = { ...prev };
                        Object.values(newRect)?.forEach((r) => {
                            r.start = null;
                            r.end = null;
                            r.show = false;
                            r.isComplete = false;
                        })
                        return newRect;
                    });
                    setAIAutoCountPoly(prev => {
                        let newPoly = { ...prev };
                        Object.values(newPoly)?.forEach((p) => {
                            p.points = [];
                            p.nextPoint = null;
                            p.isComplete = false;
                            p.show = false;
                        })
                        return newPoly;
                    });
                    setAIAutoMeasureLengthRect(prev => {
                        let newRect = { ...prev };
                        Object.values(newRect)?.forEach((r) => {
                            r.start = null;
                            r.end = null;
                            r.is_complete = false;
                            r.show = false;
                        })
                        return newRect;
                    })
                    setAIAutoFinding(prev => {
                        let newFind = { ...prev };
                        Object.values(newFind)?.forEach((f) => {
                            f.start = null;
                            f.end = null;
                            f.show = false;
                        })
                        return newFind;
                    });
                    setHintingSAM(false);
                    setHintingAutoMeasure(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'show': false,
                        }
                    }))
                    setHintingAutoMeasurePerim(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'show': false,
                        }
                    }))
                    setHintingAutoFindDW(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'show': false,
                        }
                    }))
                    setSelectingLegend(false);
                    setDrawingLegend(false);
                    setDrawingLegendEntry(false);
                    setDrawingLegendEntryRect({
                        'start': null,
                        'end': null,
                    })
                    //setCurrentLegend(null);
                    setAIAutoCountExample(prev => ({
                        ...prev,
                        [pageID]: {
                            symbol: null,
                            show: false,
                        }
                    }))
                }
            } else if (e.key === 'Backspace' || e.key === 'Delete') {
                if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
                    if (drawingCount && countDots.length > 0) {
                        let mostRecentDot = countDots[countDots.length - 1];
                        setCountDots(prev => prev.slice(0, -1));
                        setCountHistory(prev => [...prev, mostRecentDot]);
                    } else if ((currentMeasurement || selectedMeasurements.length > 0) && !editingMeasurement && !drawingRectangle && !drawingPolygon && !drawingCount && !drawingLine && !drawingCircle && !cuttingPolygon && !cuttingRectangle && !splittingPolygon && !splittingRectangle && !cuttingPolygonRect && !cuttingRectangleRect) {
                        if (!currentMeasurement && new Set(selectedMeasurements).size === 1) {
                            setCurrentMeasurement(selectedMeasurements[0]);
                        }

                        setShowDeleteMeasurementModal(true);

                        if (showDeleteMeasurementModal) {
                            if (new Set(selectedMeasurements).size > 1) {
                                DeleteMeasurements();
                            } else {
                                DeleteMeasurement(currentMeasurement ? measurements[currentMeasurement] : measurements[selectedMeasurements[0]]);
                            }
                            setShowDeleteMeasurementModal(false);
                            setCurrentMeasurement(null);
                        }
                    } else if (currentAnnotation || selectedAnnotations.length > 0) {
                        setShowDeleteAnnotationModal(true);

                        if (showDeleteAnnotationModal) {
                            if (new Set(selectedAnnotations).size > 1) {
                                handleDeleteAnnotations();
                            } else {
                                handleDeleteAnnotation(currentAnnotation);
                            }
                            setShowDeleteAnnotationModal(false);
                        }
                    }
                }
            } else if (e.key === 'Tab') {
                if (document.activeElement.tagName !== 'INPUT' && drawingCount && setCountHistory.length > 0) {
                    e.stopPropagation();
                    e.preventDefault();

                    if (countHistory.length > 0) {
                        let oldDot = countHistory[countHistory.length - 1];
                        setCountDots(prev => [...prev, oldDot]);
                        setCountHistory(prev => prev.slice(0, -1));
                    }
                }
            } else if (e.key === 'Enter' || e.key === 'Return' || e.key === 'enter' || e.key === 'return') {
                e.target.style.cursor = 'default';

                setAddingCount(false);
                setDeletingCount(false);

                if (currentMeasurement && !editingMeasurement && showDeleteMeasurementModal) {
                    if (new Set(selectedMeasurements).size > 1) {
                        DeleteMeasurements();
                    } else {
                        DeleteMeasurement(currentMeasurement ? measurements[currentMeasurement] : measurements[selectedMeasurements[0]]);
                    }
                    setShowDeleteMeasurementModal(false);
                    setCurrentMeasurement(null);
                }

                if (drawingCount) {
                    if (countDots.length > 0) {
                        addCount();
                    }
                }
            } else if (!e.defaultPrevented && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA' && !editingMeasurement && !showDeleteMeasurementModal) {
                handleKeyBind({
                    e,
                    pageID,
                    project,
                    measurements,
                    keybinds,
                    shiftDown, setShiftDown,
                    controlDown, setControlDown,
                    optionDown, setOptionDown,
                    tabDown, setTabDown,
                    metaDown, setMetaDown,
                    handleZoom, handleResetZoom, handleZoomToMeasurement,
                    setShowMiniMap,
                    setDrawingCount, setDrawingLine, setDrawingPolygon, setDrawingRectangle, setDrawingScale, setDrawingCircle,
                    setAddingCount, setDeletingCount, setCuttingPolygon, setCuttingRectangle, setSplittingPolygon, setSplittingRectangle, setCuttingPolygonRect, setCuttingRectangleRect, setErasingCount, setSelectionCalculator,
                    currentMeasurement, setCurrentMeasurement,
                    takeoffSettings, setTakeoffSettings,
                    handleChangeTakeoffSettings,
                    handleUndo, handleRedo,
                    changingHistory,
                    stopWatch,
                    setHintingSAM,
                    setAIAutoClassifying, setAIAutoFinding,
                    setAIAutoCountRect, setAIAutoCountPoly,
                    handleTurnOffDrawing,
                    setDrawingAnnotationArrow, setDrawingAnnotationText, setDrawingAnnotationRectangle,
                });
            }

            if (optionDown && e.keyCode === 37) {
                let pagesList = Object.values(pages).sort((a, b) => a.parent_file - b.parent_file || a.page_number - b.page_number);

                let newPageID = pagesList[(pagesList.findIndex(p => String(p.id) === String(pageID)) - 1 + pagesList.length) % pagesList.length].id;

                handleNewPage(newPageID);
                window.history.pushState({}, '', `/takeoff/${project.uuid}/${newPageID}`);

                setTimeout(() => {
                    if (takeoffSettings?.show_pages_sidebar) {
                        const element = document.getElementById("page-" + newPageID);
                        if (element) element.scrollIntoView();
                    }
                }, 100);
            }

            if (optionDown && e.keyCode === 39) {
                let pagesList = Object.values(pages).sort((a, b) => a.parent_file - b.parent_file || a.page_number - b.page_number);

                let newPageID = pagesList[(pagesList.findIndex(p => String(p.id) === String(pageID)) + 1 + pagesList.length) % pagesList.length].id;

                handleNewPage(newPageID);
                window.history.pushState({}, '', `/takeoff/${project.uuid}/${newPageID}`);

                setTimeout(() => {
                    if (takeoffSettings?.show_pages_sidebar) {
                        const element = document.getElementById("page-" + newPageID);
                        if (element) element.scrollIntoView();
                    }
                }, 100);
            }

            //down arrow
            if (currentMeasurement) {
                if (e.keyCode === 40) {
                    let tempMeasurement = moveCurrentMeasurementDown();
                    if (tempMeasurement) {
                        setCurrentMeasurement(tempMeasurement);
                        setSelectedMeasurements([tempMeasurement]);

                        if (controlDown && !measurements[tempMeasurement].hide && measurements[tempMeasurement].page == pageID) {
                            handleZoomToMeasurement(tempMeasurement);
                        }
                    }
                } else if (e.keyCode === 38) {
                    let tempMeasurement = moveCurrentMeasurementUp();
                    if (tempMeasurement) {
                        setCurrentMeasurement(tempMeasurement);
                        setSelectedMeasurements([tempMeasurement]);

                        if (controlDown && !measurements[tempMeasurement].hide && measurements[tempMeasurement].page == pageID) {
                            handleZoomToMeasurement(tempMeasurement);
                        }
                    }
                }
            } else {
                //largest index
                if ((e.keyCode === 40 || e.keyCode === 38) && Object.values(measurements).filter((m) => !m.hide && m.page === pageID).length > 0) {
                    let tempMeasurement = Object.values(measurements).filter((m) => !m.hide && m.page === pageID).reduce((a, b) => a.index > b.index ? a : b).id;

                    if (tempMeasurement) {
                        setCurrentMeasurement(tempMeasurement);
                        setSelectedMeasurements([tempMeasurement]);

                        if (controlDown) {
                            handleZoomToMeasurement(tempMeasurement);
                        }
                    }
                }
            }
        };

        const handleKeyUp = (e) => {
            if (selecting && e.keyCode === 16) {
                setSelecting(false);
            }

            if (e.key === 'Shift') {
                setShiftDown(false);
            }
            if (e.key === 'Meta' || e.key === 'Control') {
                setControlDown(false);
            }
            if (e.key === 'Option' || e.key === 'Alt') {
                setOptionDown(false);
            }
            if (e.key === 'Tab') {
                setTabDown(false);
            }
        }

        const onBlur = (e) => {
            setOptionDown(false);
            setControlDown(false);
            setShiftDown(false);
            setTabDown(false);
            setMetaDown(false);
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        window.addEventListener("blur", onBlur);
        //window.addEventListener("click", onWindowClick);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
            window.removeEventListener("blur", onBlur);
            //window.removeEventListener("click", onWindowClick);
        };
    }, [drawingCount, currentMeasurement, countDots, onWindowClick, selecting, showDeleteMeasurementModal, editingMeasurement, drawingLine, handleRedo, handleUndo, history, currentHistory, changingHistory, keybinds, measurements, pages, project, selectedMeasurements, showMiniMap, controlDown, optionDown, shiftDown, tabDown, currentAnnotation, showDeleteAnnotationModal, selectedAnnotations]);

    const handleUpdateProject = (newProject) => {
        setProject(newProject);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: newProject,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleClickMeasurement = (e, measurement) => {
        e.preventDefault();

        if (takeoffSettings?.sort === 'custom') {
            if (e.ctrlKey || e.metaKey) {
                setCurrentMeasurement(null);
                setSelectedMeasurements(prev => {
                    if (prev.length && prev.find((a) => a === measurement.id)) {
                        return prev.filter((a) => a !== measurement.id);
                    }
                    return [...prev, measurement.id];
                })
                setMultiselectAnchor1(measurement.index);
                setMultiselectAnchor2(null);

                console.log('click');
            } else if (e.shiftKey) {
                if (!multiselectAnchor1 && !currentMeasurement) {
                    setSelectedMeasurements(Object.values(measurements).filter((m) => m.index <= measurement.index).map((m) => m.id));
                    setSelectedGroups(Object.values(groups).filter((g) => g.index <= measurement.index).map((g) => g.id));
                    setMultiselectAnchor1(measurement.index);
                } else if (multiselectAnchor1 !== null) {
                    let newSelectedMeasurements = [...selectedMeasurements];
                    let newSelectedGroups = [...selectedGroups];

                    if (multiselectAnchor2) {
                        newSelectedMeasurements = newSelectedMeasurements.filter((m) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && measurements[m].index <= multiselectAnchor1
                                && measurements[m].index >= multiselectAnchor2) {
                                return false;
                            } else if (measurements[m].index >= multiselectAnchor1
                                && measurements[m].index <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        });

                        newSelectedGroups = newSelectedGroups?.filter((g) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && groups[g].index <= multiselectAnchor1
                                && groups[g].index >= multiselectAnchor2) {
                                return false;
                            } else if (groups[g].index >= multiselectAnchor1
                                && groups[g].index <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        })
                    }

                    newSelectedMeasurements = newSelectedMeasurements.concat(Object.values(measurements).filter((m) => {
                        if (multiselectAnchor1 > measurement.index) {
                            return m.index >= measurement.index && m.index <= multiselectAnchor1;
                        }
                        return m.index <= measurement.index && m.index >= multiselectAnchor1;
                    }).map((m) => m.id));

                    newSelectedGroups = newSelectedGroups.concat(Object.values(groups).filter((g) => {
                        if (multiselectAnchor1 > measurement.index) {
                            return g.index >= measurement.index && g.index <= multiselectAnchor1;
                        }
                        return g.index <= measurement.index && g.index >= multiselectAnchor1;
                    }).map((g) => g.id));

                    setSelectedMeasurements(newSelectedMeasurements);
                    setSelectedGroups(newSelectedGroups);
                    setMultiselectAnchor2(measurement.index);
                }
            } else {
                setMultiselectAnchor1(measurement.index);
                setMultiselectAnchor2(null);
                setSelectedMeasurements([measurement.id]);
                setSelectedGroups([]);
            }
        } else {
            let flattenedTree = []

            const flattenTree = (tree) => {
                tree.forEach((node) => {
                    flattenedTree.push(node);
                    if (node.children) {
                        flattenTree(node.children);
                    }
                })
            }

            flattenTree(tree);

            if (e.ctrlKey || e.metaKey) {
                setCurrentMeasurement(null);
                setSelectedMeasurements(prev => {
                    if (prev.length && prev.find((a) => a === measurement.id)) {
                        return prev.filter((a) => a !== measurement.id);
                    }
                    return [...prev, measurement.id];
                })
                setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(measurement.id)));
                setMultiselectAnchor2(null);
            } else if (e.shiftKey) {
                if (!multiselectAnchor1 && !currentMeasurement) {
                    let subtree = flattenedTree.slice(0, flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(measurement.id)));

                    setSelectedMeasurements(subtree.filter((m) => m.id.includes('measurement')).map((m) => Number(m.id.split('-')[1])));
                    setSelectedGroups(subtree.filter((m) => m.id.includes('group')).map((m) => Number(m.id.split('-')[1])));
                    setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(measurement.id)));
                } else if (multiselectAnchor1 !== null) {
                    let subtree = flattenedTree.filter((m) => selectedGroups.includes(Number(m.id.split('-')[1])) || selectedMeasurements.includes(Number(m.id.split('-')[1])));
                    let secondIndex = flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(measurement.id));

                    if (multiselectAnchor2) {
                        subtree = subtree.filter((m) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && (flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) <= multiselectAnchor1
                                    && flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) >= multiselectAnchor2)) {
                                return false;
                            } else if (flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) >= multiselectAnchor1
                                && flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        })
                    }

                    subtree = subtree.concat(flattenedTree.slice(Math.min(multiselectAnchor1, secondIndex), Math.max(multiselectAnchor1, secondIndex) + 1));

                    setSelectedMeasurements(subtree.filter((m) => m.id.includes('measurement')).map((m) => Number(m.id.split('-')[1])));
                    setSelectedGroups(subtree.filter((m) => m.id.includes('group')).map((m) => Number(m.id.split('-')[1])));
                    setMultiselectAnchor2(secondIndex);
                }
            } else {
                setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(measurement.id)));
                setMultiselectAnchor2(null);
                setSelectedMeasurements([measurement.id]);
                setSelectedGroups([]);
            }
        }
    }

    const handleClickGroup = (e, group) => {
        e.preventDefault();

        if (takeoffSettings?.sort === 'custom') {
            if (e.ctrlKey || e.metaKey) {
                setSelectedGroups(prev => {
                    if (prev.length && prev.find((a) => a === group.id)) {
                        return prev.filter((a) => a !== group.id);
                    }
                    return [...prev, group.id];
                })
                setCurrentMeasurement(null);
                setMultiselectAnchor1(group.index);
                setMultiselectAnchor2(null);
            } else if (e.shiftKey) {
                if (!multiselectAnchor1 && !currentMeasurement) {
                    setSelectedGroups(Object.values(groups).filter((g) => g.index <= group.index).map((g) => g.id));
                    setSelectedMeasurements(Object.values(measurements).filter((m) => m.index <= group.index).map((m) => m.id));
                    setMultiselectAnchor1(group.index);
                } else if (multiselectAnchor1) {
                    let newSelectedGroups = [...selectedGroups];
                    let newSelectedMeasurements = [...selectedMeasurements];

                    if (multiselectAnchor2) {
                        newSelectedGroups = newSelectedGroups?.filter((g) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && groups[g].index <= multiselectAnchor1
                                && groups[g].index >= multiselectAnchor2) {
                                return false;
                            } else if (groups[g].index >= multiselectAnchor1
                                && groups[g].index <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        })

                        newSelectedMeasurements = newSelectedMeasurements?.filter((m) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && measurements[m].index <= multiselectAnchor1
                                && measurements[m].index >= multiselectAnchor2) {
                                return false;
                            } else if (measurements[m].index >= multiselectAnchor1
                                && measurements[m].index <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        })
                    }

                    newSelectedGroups = newSelectedGroups.concat(Object.values(groups).filter((g) => {
                        if (multiselectAnchor1 > group.index) {
                            return g.index >= group.index && g.index <= multiselectAnchor1;
                        }
                        return g.index <= group.index && g.index >= multiselectAnchor1;
                    }).map((g) => g.id));

                    newSelectedMeasurements = newSelectedMeasurements.concat(Object.values(measurements).filter((m) => {
                        if (multiselectAnchor1 > group.index) {
                            return m.index >= group.index && m.index <= multiselectAnchor1;
                        }
                        return m.index <= group.index && m.index >= multiselectAnchor1;
                    }).map((m) => m.id));

                    setSelectedGroups(newSelectedGroups);
                    setMultiselectAnchor2(group.index);
                    setSelectedMeasurements(newSelectedMeasurements);
                }
            } else {
                setMultiselectAnchor1(group.index);
                setMultiselectAnchor2(null);
                setSelectedGroups([group.id]);
                setSelectedMeasurements([]);
            }
        } else {
            let flattenedTree = []

            const flattenTree = (tree) => {
                tree.forEach((node) => {
                    flattenedTree.push(node);
                    if (node.children) {
                        flattenTree(node.children);
                    }
                })
            }

            flattenTree(tree);

            if (e.ctrlKey || e.metaKey) {
                setSelectedGroups(prev => {
                    if (prev.length && prev.find((a) => a === group.id)) {
                        return prev.filter((a) => a !== group.id);
                    }
                    return [...prev, group.id];
                })
                setCurrentMeasurement(null);
                setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(group.id)));
                setMultiselectAnchor2(null);
            } else if (e.shiftKey) {
                setCurrentMeasurement(null);

                if (!multiselectAnchor1 && !currentMeasurement) {
                    let subtree = flattenedTree.slice(0, flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(group.id)));

                    setSelectedGroups(subtree.filter((m) => m.id.includes('group')).map((m) => Number(m.id.split('-')[1])));
                    setSelectedMeasurements(subtree.filter((m) => m.id.includes('measurement')).map((m) => Number(m.id.split('-')[1])));
                    setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(group.id)));
                } else if (multiselectAnchor1 !== null) {
                    let subtree = flattenedTree.filter((m) => selectedGroups.includes(Number(m.id.split('-')[1])) || selectedMeasurements.includes(Number(m.id.split('-')[1])));
                    let secondIndex = flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(group.id));

                    if (multiselectAnchor2) {
                        subtree = subtree.filter((m) => {
                            if (multiselectAnchor1 > multiselectAnchor2
                                && (flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) <= multiselectAnchor1
                                    && flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) >= multiselectAnchor2)) {
                                return false;
                            } else if (flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) >= multiselectAnchor1
                                && flattenedTree.findIndex((n) => Number(n.id.split('-')[1]) === Number(m.id.split('-')[1])) <= multiselectAnchor2) {
                                return false;
                            }
                            return true;
                        })
                    }

                    subtree = subtree.concat(flattenedTree.slice(Math.min(multiselectAnchor1, secondIndex), Math.max(multiselectAnchor1, secondIndex) + 1));

                    setSelectedGroups(subtree.filter((m) => m.id.includes('group')).map((m) => Number(m.id.split('-')[1])));
                    setMultiselectAnchor2(secondIndex);
                    setSelectedMeasurements(subtree.filter((m) => m.id.includes('measurement')).map((m) => Number(m.id.split('-')[1])));
                }
            } else {
                setMultiselectAnchor1(flattenedTree.findIndex((m) => Number(m.id.split('-')[1]) === Number(group.id)));
                setMultiselectAnchor2(null);
                setSelectedGroups([group.id]);
                setSelectedMeasurements([]);
            }
        }
    }

    const createMeasurement = (data, disabled, name, quite) => {
        setMeasurements(prev => {
            let newMeasurements = { ...prev };
            newMeasurements[data.measurement.id] = data.measurement;

            if (name) {
                delete newMeasurements[name];
            }
            return newMeasurements;
        })

        //setGroups(data.groups)
        setTree(data.tree)

        if (!quite) {
            setCurrentMeasurement(data.measurement.id);
        }

        if (!disabled) {
            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    action: "add",
                    previous: null,
                    current: data.measurement,
                });

                console.log(newHistory);
                return newHistory;
            })
            setCurrentHistory(prev => prev + 1);
        }

        setPages(prev => ({
            ...prev,
            [data.measurement.page]: {
                ...prev[data.measurement.page],
                'measurements': prev[data.measurement.page].measurements + 1,
            }
        }))
    }

    const createMeasurements = (data, name) => {
        setMeasurements(prev => {
            let newMeasurements = { ...prev };
            data?.measurements?.forEach((m) => {
                newMeasurements[m.id] = m;
            })
            delete newMeasurements[name];
            return newMeasurements;
        })

        let newHistory = [...history];
        let newCurrentHistory = currentHistory;

        data?.measurements?.forEach(m => {
            newHistory = newHistory.slice(0, newCurrentHistory + 1);

            newHistory.push({
                action: "add",
                previous: null,
                current: m,
            });

            newCurrentHistory += 1;
        })

        setHistory(newHistory);
        setCurrentHistory(newCurrentHistory);

        //setGroups(data.groups)
        setTree(data.tree)

        setCurrentMeasurement(data.measurements[0].id);
        setSelectedMeasurements(prev => ([...prev, ...data.measurements.map((m) => m.id)]));

        setPages(prev => ({
            ...prev,
            [data.measurements[0].page]: {
                ...prev[data.measurements[0].page],
                'measurements': prev[data.measurements[0].page].measurements + data.measurements.length,
            }
        }))
    }

    const updateMeasurementInState = (measurement) => {
        setMeasurements(prev => ({
            ...prev,
            [measurement.id]: measurement,
        }))
    }

    const compareTwoArrays = (arr1, arr2) => {
        if (!arr1 && !arr2) {
            return true;
        } else if (!arr1 || !arr2) {
            return false;
        }

        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].x !== arr2[i].x || arr1[i].y !== arr2[i].y || arr1[i].arc !== arr2[i].arc) {
                return false;
            }
        }

        return true;
    }

    const compareTwoSubpolygons = (sub1, sub2) => {
        console.log(sub1, sub2);

        if (!sub1 && !sub2) {
            return true;
        } else if (!sub1 || !sub2) {
            return false;
        }

        if (Object.keys(sub1).length !== Object.keys(sub2).length) {
            return false;
        }

        for (let i = 0; i < Object.keys(sub1).length; i++) {
            if (!compareTwoArrays(Object.values(sub1)[i], Object.values(sub2)[i])) {
                return false;
            }
        }

        return true;
    }

    const handleDuplicateMeasurement = (measurement) => {
        if (measurement.type === 'polygon') {
            axios({
                method: 'patch',
                url: `${API_ROUTE}/api/polygon/`,
                data: {
                    'id': measurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement,
                    }))

                    setPages(prev => ({
                        ...prev,
                        [response.data.measurement.page]: {
                            ...prev[response.data.measurement.page],
                            'measurements': prev[response.data.measurement.page].measurements + 1,
                        }
                    }))

                    setCurrentMeasurement(response.data.measurement.id);
                    setSelectedMeasurements([response.data.measurement.id]);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (measurement.type === 'count') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/duplicate-count/`,
                data: {
                    'id': measurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement,
                    }))

                    setPages(prev => ({
                        ...prev,
                        [response.data.measurement.page]: {
                            ...prev[response.data.measurement.page],
                            'measurements': prev[response.data.measurement.page].measurements + 1,
                        }
                    }))

                    setCurrentMeasurement(response.data.measurement.id);
                    setSelectedMeasurements([response.data.measurement.id]);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (measurement.type === 'line') {
            axios({
                method: 'patch',
                url: `${API_ROUTE}/api/line/`,
                data: {
                    'id': measurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement,
                    }))

                    setPages(prev => ({
                        ...prev,
                        [response.data.measurement.page]: {
                            ...prev[response.data.measurement.page],
                            'measurements': prev[response.data.measurement.page].measurements + 1,
                        }
                    }))

                    setCurrentMeasurement(response.data.measurement.id);
                    setSelectedMeasurements([response.data.measurement.id]);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (measurement.type === 'circle') {
            axios({
                method: 'patch',
                url: `${API_ROUTE}/api/circle/`,
                data: {
                    'id': measurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement,
                    }))

                    setPages(prev => ({
                        ...prev,
                        [response.data.measurement.page]: {
                            ...prev[response.data.measurement.page],
                            'measurements': prev[response.data.measurement.page].measurements + 1,
                        }
                    }))

                    setCurrentMeasurement(response.data.measurement.id);
                    setSelectedMeasurements([response.data.measurement.id]);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (measurement.type === 'rectangle') {
            axios({
                method: 'patch',
                url: `${API_ROUTE}/api/rectangle/`,
                data: {
                    'id': measurement.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement,
                    }))

                    setPages(prev => ({
                        ...prev,
                        [response.data.measurement.page]: {
                            ...prev[response.data.measurement.page],
                            'measurements': prev[response.data.measurement.page].measurements + 1,
                        }
                    }))

                    setCurrentMeasurement(response.data.measurement.id);
                    setSelectedMeasurements([response.data.measurement.id]);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleDuplicateGroup = (group) => {
        axios({
            method: 'patch',
            url: `${API_ROUTE}/api/group/`,
            data: {
                'id': group.id,
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                setMeasurements(response.data.measurements);
                setGroups(response.data.groups);

                setPages(prev => {
                    let newPages = { ...prev };
                    Object.values(newPages).forEach((p) => {
                        p.measurements = 0;
                    });

                    Object.values(response.data.measurements).forEach((m) => {
                        if (newPages[m.page]) {
                            newPages[m.page].measurements += 1;
                        }
                    });

                    return newPages;
                })

                setCurrentMeasurement(null);
                setSelectedMeasurements([]);
                setTree(response.data.tree);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleRectTransform = (measurement, disabled) => {
        let previous = measurements[measurement.id];

        if (!disabled && (previous.rectangle !== measurement.rectangle || previous.width !== measurement.width || previous.height !== measurement.height)) {
            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    'action': 'edit',
                    'previous': previous,
                    'current': measurement,
                });
                return newHistory;
            });
            setCurrentHistory(prev => prev + 1);
        }

        if (currentMeasurement && measurement.id === currentMeasurement) {
            setCurrentMeasurement(measurement.id);
        }

        updateMeasurementInState(measurement);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/rectangle/`,
            data: measurement,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handlePolygonTransform = (measurement, prev, disabled, callback = () => { }) => {
        let previous = prev
            ? prev
            : measurements[measurement.id]

        console.log(previous, measurement);

        if (!disabled && previous && (!compareTwoArrays(previous.polygon_dots, measurement.polygon_dots) || !compareTwoSubpolygons(previous.sub_polygons || {}, measurement.sub_polygons || {}))) {
            console.log('edit');

            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    'action': 'edit',
                    'previous': previous,
                    'current': measurement,
                });
                return newHistory;
            });
            setCurrentHistory(prev => prev + 1);
        }

        if (currentMeasurement && measurement.id === currentMeasurement) {
            setCurrentMeasurement(measurement.id);
        }

        updateMeasurementInState(measurement);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                ...measurement,
                'userID': auth.user.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                updateMeasurementInState(response.data);
                callback();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleLineTransform = (measurement, disabled, prev, callback = () => { }) => {
        let previous = prev
            ? prev
            : measurements[measurement.id]

        if (!disabled, previous && !compareTwoArrays(previous.line_dots, measurement.line_dots)) {
            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    'action': 'edit',
                    'previous': previous,
                    'current': measurement,
                });
                return newHistory;
            });
            setCurrentHistory(prev => prev + 1);
        }

        if (currentMeasurement && measurement.id === currentMeasurement) {
            setCurrentMeasurement(measurement.id);
        }

        updateMeasurementInState(measurement);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/line/`,
            data: {
                ...measurement,
                'userID': auth.user.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                updateMeasurementInState(response.data);
                callback();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const addCount = () => {
        if (!project?.include_count) {
            handleChangeFilter('include_count', true);
        }

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'hide': false,
                'type': 'count',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'size': 5,
                'shape': 'circle',
                ['count_dots']: [...countDots],
            }
        }))

        console.log(stopWatch);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/count/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'dots': countDots,
                'time': stopWatch.totalSeconds,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);
                createMeasurements(response.data, tempName);
            })
            .catch((error) => {
                console.log(error);
            });

        stopWatch.reset();
        setCountDots([]);
    }

    const handleCircleTransform = (measurement, disabled, prev) => {
        let previous = prev
            ? prev
            : measurements[measurement.id]

        console.log(previous, measurement);

        if (!disabled && previous && (previous.circle?.radius !== measurement.circle?.radius || previous.circle?.x !== measurement.circle?.x || previous.circle?.y !== measurement.circle?.y)) {
            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    'action': 'edit',
                    'previous': previous,
                    'current': measurement,
                });
                return newHistory;
            });
            setCurrentHistory(prev => prev + 1);
        }

        if (currentMeasurement && measurement.id === currentMeasurement) {
            setCurrentMeasurement(measurement.id);
        }

        updateMeasurementInState(measurement);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/circle/`,
            data: measurement,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleCountTransform = (measurement, disabled, method, dotID, tempID, deleteDots) => {
        let previous = measurements[measurement.id];

        if (!disabled && previous && !compareTwoArrays(previous.count_dots, measurement.count_dots)) {
            console.log('edit', previous, measurement);

            setHistory(prev => {
                let newHistory = [...prev];
                newHistory = newHistory.slice(0, currentHistory + 1);

                newHistory.push({
                    'action': 'edit',
                    'previous': previous,
                    'current': measurement,
                });
                return newHistory;
            });
            setCurrentHistory(prev => prev + 1);
        }

        if (currentMeasurement && measurement.id === currentMeasurement) {
            setCurrentMeasurement(measurement.id);
        }

        updateMeasurementInState(measurement);

        if (method === 'delete') {
            axios({
                method: 'delete',
                url: `${API_ROUTE}/api/count/`,
                data: {
                    'dotID': dotID,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setAILocalization(response.data.localization_symbols);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (method === 'add') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/add-dot-to-count/`,
                data: {
                    'count': measurement.id,
                    'x': measurement.count_dots[measurement.count_dots.length - 1].x,
                    'y': measurement.count_dots[measurement.count_dots.length - 1].y,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(prev => {
                        let index = prev[measurement.id].count_dots.findIndex((a) => a.id === tempID);

                        return {
                            ...prev,
                            [measurement.id]: {
                                ...prev[measurement.id],
                                'count_dots': [
                                    ...prev[measurement.id].count_dots.splice(0, index),
                                    response.data.dot,
                                    ...prev[measurement.id].count_dots.splice(index + 1),
                                ]
                            }
                        }
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (method === 'group-delete') {
            axios({
                method: 'put',
                url: `${API_ROUTE}/api/erase-count/`,
                data: {
                    pageID: pageID,
                    dots: deleteDots,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setMeasurements(response.data.measurements);
                    setAILocalization(response.data.localization_symbols);
                })
                .catch((error) => {
                    console.log(error);
                })

        } else {
            axios({
                method: 'put',
                url: `${API_ROUTE}/api/count/`,
                data: {
                    ...measurement,
                    'userID': auth.user.id,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    if (method === 'add') {
                        updateMeasurementInState(response.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const groupMeasurement = ({ id, group }) => {
        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-measurement-group/`,
            'data': {
                'id': id,
                'group': group,
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setTree(res.data.tree);
                setGroups(res.data.groups);
                setMeasurements(res.data.measurements);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const DeleteMeasurements = (measurementsToDelete) => {
        let newHistory = [...history.splice(0, currentHistory + 1)];
        let newCurrentHistory = currentHistory;

        //unique elements in selectedMeasurements new Set(selectedMeasurements), conver to array after
        let temp = Array.from(new Set(selectedMeasurements));

        if (measurementsToDelete) {
            temp = measurementsToDelete;
        }

        console.log(temp);

        let newPages = { ...pages };

        temp?.forEach((m) => {
            if (!measurements[m]) return

            newHistory.push({
                'action': 'delete',
                'previous': measurements[m],
                'current': null,
            });
            newCurrentHistory++;

            newPages[measurements[m].page] = {
                ...newPages[measurements[m].page],
                'measurements': newPages[measurements[m].page].measurements - 1,
            }
        })

        setPages(newPages);

        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            temp?.forEach((m) => {
                delete newMeasurements[m];
            })
            return newMeasurements;
        });

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/delete-measurements/`,
            data: {
                'ids': temp
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                if (response.data?.localization_symbols) {
                    setAILocalization(response.data.localization_symbols);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setHistory(newHistory);
        setCurrentHistory(newCurrentHistory);

        setSelectedMeasurements([]);
        setCurrentMeasurement(null);
    }

    const DeleteMeasurement = (measurement) => {
        setHistory(prev => {
            let newHistory = [...prev];
            newHistory = newHistory.slice(0, currentHistory + 1);

            newHistory.push({
                'action': 'delete',
                'previous': measurement,
                'current': null,
            });
            return newHistory;
        });
        setCurrentHistory(prev => prev + 1);

        setMeasurements(prev => {
            let newMeasurements = { ...prev };
            delete newMeasurements[measurement.id];
            return newMeasurements;
        });

        if (currentMeasurement === measurement.id) {
            setCurrentMeasurement(null);
        }

        if (selectedMeasurements.find((a) => a === measurement.id)) {
            setSelectedMeasurements(prev => prev.filter((a) => a !== measurement.id));
        }

        setPages(prev => ({
            ...prev,
            [measurement.page]: {
                ...prev[measurement.page],
                'measurements': prev[measurement.page].measurements - 1,
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/delete-measurement/`,
            data: {
                'id': measurement.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                if (measurement.type === 'count') {
                    setAILocalization(response.data.localization_symbols);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleZoom = (newZoomScale) => {
        const center = {
            x: -pages[pageID].position_x / pages[pageID].zoom + (window.innerWidth * 0.8 / 2) / pages[pageID].zoom,
            y: -pages[pageID].position_y / pages[pageID].zoom + (window.innerHeight * 0.9 / 2) / pages[pageID].zoom
        }

        let newZoom = Math.min(Math.max(0.025, pages[pageID].zoom * newZoomScale), 25);

        const newX = -center.x * newZoom + (window.innerWidth * 0.8 / 2);
        const newY = -center.y * newZoom + (window.innerHeight * 0.9 / 2);

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                zoom: newZoom,
                position_x: newX,
                position_y: newY,
            }
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                project: pages[pageID].project,
                zoom: pages[pageID].zoom * newZoomScale,
                position_x: newX,
                position_y: newY,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleZoomToDot = (dot) => {
        let measurement = measurements[dot.measurement];

        let newZoomScale = Math.min(window.innerWidth * 0.5 / (measurement.zoom * 4), window.innerHeight * 0.5 / (measurement.zoom * 4));
        let newX = (window.innerWidth - dot.x * newZoomScale * 2) / 2;
        let newY = (window.innerHeight - dot.y * newZoomScale * 2) / 2;

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            }
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                project: pages[pageID].project,
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleZoomToAnnotation = (annotationID) => {
        if (!annotationID && !currentAnnotation) {
            handleResetZoom();
            return;
        }

        if (!annotationID) {
            annotationID = currentAnnotation;
        }

        let maxX, minX, maxY, minY;

        if (annotations[annotationID].type === 'text') {
            maxX = annotations[annotationID].text.x + annotations[annotationID].text.width;
            minX = annotations[annotationID].text.x;
            maxY = annotations[annotationID].text.y + annotations[annotationID].text.height;
            minY = annotations[annotationID].text.y;
        } else if (annotations[annotationID].type === 'arrow') {
            maxX = Math.max(annotations[annotationID].arrow.x1, annotations[annotationID].arrow.x1 + annotations[annotationID].arrow.x2);
            minX = Math.min(annotations[annotationID].arrow.x1, annotations[annotationID].arrow.x1 + annotations[annotationID].arrow.x2);
            maxY = Math.max(annotations[annotationID].arrow.y1, annotations[annotationID].arrow.y1 + annotations[annotationID].arrow.y2);
            minY = Math.min(annotations[annotationID].arrow.y1, annotations[annotationID].arrow.y1 + annotations[annotationID].arrow.y2);
        } else if (annotations[annotationID].type === 'rectangle') {
            maxX = annotations[annotationID].rectangle.x + annotations[annotationID].rectangle.width;
            minX = annotations[annotationID].rectangle.x;
            maxY = annotations[annotationID].rectangle.y + annotations[annotationID].rectangle.height;
            minY = annotations[annotationID].rectangle.y;
        }

        let newZoomScale = Math.min(window.innerWidth * 0.5 / (maxX - minX), window.innerHeight * 0.5 / (maxY - minY));

        let newX = (window.innerWidth - (maxX + minX) * newZoomScale) / 2;
        let newY = (window.innerHeight - (maxY + minY) * newZoomScale) / 2;

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            }
        }))
    }

    const handleZoomToMeasurement = (measurementID) => {
        if (!measurementID && !currentMeasurement) {
            handleResetZoom();
            return;
        }

        if (!measurementID) {
            measurementID = currentMeasurement;
        }

        if (measurements[measurementID].page != pageID) {
            handleNewPage(measurements[measurementID].page);
            window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurements[measurementID].page}`);
        }

        let measurement = measurements[measurementID];

        let maxX, minX, maxY, minY;

        if (measurement?.type === 'rectangle') {
            maxX = Math.max(measurement.rectangle.x, measurement.rectangle.x + measurement.width);
            minX = Math.min(measurement.rectangle.x, measurement.rectangle.x + measurement.width);
            maxY = Math.max(measurement.rectangle.y, measurement.rectangle.y + measurement.height);
            minY = Math.min(measurement.rectangle.y, measurement.rectangle.y + measurement.height);
        } else if (measurement.type === 'polygon') {
            maxX = measurement.polygon_dots.reduce((max, dot) => Math.max(max, dot.x), measurement.polygon_dots[0].x);
            minX = measurement.polygon_dots.reduce((min, dot) => Math.min(min, dot.x), measurement.polygon_dots[0].x);
            maxY = measurement.polygon_dots.reduce((max, dot) => Math.max(max, dot.y), measurement.polygon_dots[0].y);
            minY = measurement.polygon_dots.reduce((min, dot) => Math.min(min, dot.y), measurement.polygon_dots[0].y);
        } else if (measurement.type === 'line') {
            maxX = measurement.line_dots.reduce((max, dot) => Math.max(max, dot.x), measurement.line_dots[0].x);
            minX = measurement.line_dots.reduce((min, dot) => Math.min(min, dot.x), measurement.line_dots[0].x);
            maxY = measurement.line_dots.reduce((max, dot) => Math.max(max, dot.y), measurement.line_dots[0].y);
            minY = measurement.line_dots.reduce((min, dot) => Math.min(min, dot.y), measurement.line_dots[0].y);
        } else if (measurement.type === 'count' && measurement.count_dots.length > 0) {
            if (measurement.count_dots.length === 1) {
                maxX = measurement.count_dots[0].x + measurement.zoom * 2;
                minX = measurement.count_dots[0].x - measurement.zoom * 2;
                maxY = measurement.count_dots[0].y + measurement.zoom * 2;
                minY = measurement.count_dots[0].y - measurement.zoom * 2;
            } else {
                maxX = measurement.count_dots.reduce((max, dot) => Math.max(max, dot.x), measurement.count_dots[0].x);
                minX = measurement.count_dots.reduce((min, dot) => Math.min(min, dot.x), measurement.count_dots[0].x);
                maxY = measurement.count_dots.reduce((max, dot) => Math.max(max, dot.y), measurement.count_dots[0].y);
                minY = measurement.count_dots.reduce((min, dot) => Math.min(min, dot.y), measurement.count_dots[0].y);
            }
        } else if (measurement.type === 'circle') {
            maxX = measurement.circle.x + measurement.circle.radius;
            minX = measurement.circle.x - measurement.circle.radius;
            maxY = measurement.circle.y + measurement.circle.radius;
            minY = measurement.circle.y - measurement.circle.radius;
        }

        let newZoomScale = Math.min(window.innerWidth * 0.5 / (maxX - minX), window.innerHeight * 0.5 / (maxY - minY));

        let newX = (window.innerWidth - (maxX + minX) * newZoomScale) / 2;
        let newY = (window.innerHeight - (maxY + minY) * newZoomScale) / 2;

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            }
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                project: pages[pageID].project,
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleResetZoom = (p) => {
        if (!p) {
            p = pages[pageID];
        }

        let newZoomScale = Math.min(window.innerWidth * 0.8 / p.width, window.innerHeight * 0.9 / p.height);

        let newX = (window.innerWidth * 0.8 - p.width * newZoomScale) / 2;
        let newY = (window.innerHeight * 0.9 - p.height * newZoomScale) / 2;

        setPages(prev => ({
            ...prev,
            [p.id]: {
                ...prev[p.id],
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            }
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            data: {
                project: p.project,
                zoom: newZoomScale,
                position_x: newX,
                position_y: newY,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleCombineMeasurements = () => {
        const rectToPoly = (m) => {
            let x = m.rectangle.x;
            let y = m.rectangle.y;
            let width = m.width;
            let height = m.height;

            return [[
                [x, y],
                [x + width, y],
                [x + width, y + height],
                [x, y + height],
            ]]
        }

        const polyToPoly = (m) => {
            if (m.sub_polygons) {
                let polygon = [[...m.polygon_dots.map((dot) => [dot.x, dot.y])]];
                Object.values(m.sub_polygons).forEach((sub_polygon) => {
                    polygon.push([...sub_polygon.polygon_dots.map((dot) => [dot.x, dot.y])]);
                })

                return polygon;
            } else {
                let newPoly = [];
                m.polygon_dots.forEach((dot) => {
                    newPoly.push([dot.x, dot.y]);
                })
                return [newPoly];
            }
        }

        const measurementToPoly = (m) => {
            if (m.type === 'rectangle') {
                return rectToPoly(m);
            } else if (m.type === 'polygon') {
                return polyToPoly(m);
            }
        }

        const shapesRemaining = (measurementsToDelete, selectedMeasurements, newPoly) => {
            //return true if there are still selectedMeasurements not in measurementsToDelete that intersect with newPoly
            console.log(measurementsToDelete, selectedMeasurements, newPoly);

            let found = false

            selectedMeasurements.filter((m) => measurementsToDelete.indexOf(m) === -1).forEach((m) => {
                let measurement = measurements[m];
                let poly = measurementToPoly(measurement);

                console.log(poly);

                if (polygonClipping.intersection(newPoly, poly).length > 0) {
                    console.log('intersecting');
                    found = true;
                }
            })

            return found;
        }

        //merge all selected measurements that intersect with each other and the current measurement

        //1. merge all measurements intersecting with the current measurement
        //2. for loop through and merge all measurements that intersect with the new polygon that is made

        let measurementsToDelete = [currentMeasurement];
        let cur = measurements[currentMeasurement];

        let newPoly = measurementToPoly(cur);

        while (shapesRemaining(measurementsToDelete, selectedMeasurements, newPoly)) {
            console.log('shapes remaining', measurementsToDelete, selectedMeasurements, newPoly);
            selectedMeasurements.filter((m) => measurementsToDelete.indexOf(m) === -1).forEach((m) => {
                let measurement = measurements[m];
                let poly = measurementToPoly(measurement);

                console.log(poly);

                if (polygonClipping.intersection(newPoly, poly).length > 0) {
                    console.log('intersecting');

                    measurementsToDelete.push(m);
                    newPoly = polygonClipping.union(newPoly, poly);
                }
            })
        }

        console.log(newPoly);

        if (newPoly[0].length === 1) {
            handlePolygonTransform({
                ...measurements[currentMeasurement],
                type: 'polygon',
                polygon_dots: newPoly[0][0].slice(1).map((dot, i) => ({
                    x: dot[0],
                    y: dot[1],
                })),
            });
        } else {
            let sub_polygons = {}

            newPoly[0].slice(1).forEach((poly, i) => {
                sub_polygons[i] = {
                    id: i,
                    polygon_dots: poly.slice(1).map((dot, j) => ({
                        id: j,
                        x: dot[0],
                        y: dot[1],
                    })),
                }
            })

            handlePolygonTransform({
                ...measurements[currentMeasurement],
                type: 'polygon',
                polygon_dots: newPoly[0][0].slice(1).map((dot, i) => ({
                    id: i,
                    x: dot[0],
                    y: dot[1],
                })),
                sub_polygons: sub_polygons,
            });
        }

        //delete all measurements that were merged
        DeleteMeasurements(measurementsToDelete.filter((m) => m !== currentMeasurement));



        /*
        if (m1.type === 'rectangle' && m2.type === 'rectangle') {
            let newPolygons = polygonClipping.union(rectToPoly(m1), rectToPoly(m2));

            handlePolygonTransform({
                ...m1,
                type: 'polygon',
                polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                    x: dot[0],
                    y: dot[1],
                })),
            })

            DeleteMeasurement(m2);
        } else if (m1.type === 'polygon' && m2.type === 'polygon') {
            let newPolygons = polygonClipping.union(polyToPoly(m1), polyToPoly(m2));

            if (newPolygons[0].length === 1) {
                handlePolygonTransform({
                    ...m1,
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        x: dot[0],
                        y: dot[1],
                    })),
                });
            } else {
                let sub_polygons = {}

                newPolygons[0].slice(1).forEach((poly, i) => {
                    sub_polygons[i] = {
                        id: i,
                        polygon_dots: poly.slice(1).map((dot, j) => ({
                            id: j,
                            x: dot[0],
                            y: dot[1],
                        })),
                    }
                })

                handlePolygonTransform({
                    ...m1,
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                });
            }

            DeleteMeasurement(m2);
        } else if (m1.type === 'polygon' && m2.type === 'rectangle') {
            let newPolygons = polygonClipping.union(polyToPoly(m1), rectToPoly(m2));

            if (newPolygons[0].length === 1) {
                handlePolygonTransform({
                    ...m1,
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        x: dot[0],
                        y: dot[1],
                    })),
                });
            } else {
                let sub_polygons = {}

                newPolygons[0].slice(1).forEach((poly, i) => {
                    sub_polygons[i] = {
                        id: i,
                        polygon_dots: poly.slice(1).map((dot, j) => ({
                            id: j,
                            x: dot[0],
                            y: dot[1],
                        })),
                    }
                })

                handlePolygonTransform({
                    ...m1,
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                });
            }

            DeleteMeasurement(m2);
        } else if (m1.type === 'rectangle' && m2.type === 'polygon') {
            let newPolygons = polygonClipping.union(rectToPoly(m1), polyToPoly(m2));

            if (newPolygons[0].length === 1) {
                handlePolygonTransform({
                    ...m2,
                    type: 'polygon',
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        x: dot[0],
                        y: dot[1],
                    })),
                });
            } else {
                let sub_polygons = {}

                newPolygons[0].slice(1).forEach((poly, i) => {
                    sub_polygons[i] = {
                        id: i,
                        polygon_dots: poly.slice(1).map((dot, j) => ({
                            id: j,
                            x: dot[0],
                            y: dot[1],
                        })),
                    }
                })

                handlePolygonTransform({
                    ...m2,
                    type: 'polygon',
                    polygon_dots: newPolygons[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                });
            }

            DeleteMeasurement(m1);
        }*/
    }

    const handleUnincludePage = (p) => {
        if (p.group) {
            setPageGroups(prev => {
                const copy = { ...prev };
                copy[p.group].pages.splice(copy[p.group].pages.findIndex(i => Number(i.id) === Number(p.id)), 1);
                return copy;
            })
        } else {
            setUngroupedPages(prev => {
                const copy = [...prev];
                copy.splice(copy.findIndex(i => Number(i) === Number(p.id)), 1);
                return copy;
            })
        }

        setPages(prev => {
            const copy = { ...prev };
            delete copy[p.id];
            return copy;
        })

        if (String(p.id) === String(pageID)) {
            const prevPage = Object.values(pages).filter(page => String(page.id) != String(p.id))[0];
            if (!prevPage) {
                window.history.pushState({}, '', `/files/${project.uuid}`);
            }
            handleNewPage(prevPage.id);
            window.history.pushState({}, '', `/takeoff/${project.uuid}/${prevPage.id}`);
            handleResetZoom(prevPage);
        }

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            data: {
                parent_file: p.parent_file,
                project: p.project,
                is_included: false,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleNewPage = (newpageID) => {
        handleTurnOffDrawing();
        setCurrentAnnotation(null);
        setCurrentMeasurement(null);
        setSelectedAnnotations([]);
        setSelectedMeasurements([]);
        setPageID(String(newpageID));

        if (!project.include_all_pages) {
            axios({
                'method': 'post',
                'url': `${API_ROUTE}/api/takeoff/`,
                'data': {
                    'projectID': project.id,
                    'pageID': newpageID,
                    'type': 'new_page',
                },
                'headers': {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    console.log(res);

                    //setProject(res.data.project);
                    setMeasurements(res.data.measurements);
                    setGroups(res.data.groups);
                    setTree(res.data.tree);
                    setAILocalization(res.data.localization_symbols);
                    setLocalizationLegends(res.data.localization_legends);
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        if (!pages[newpageID].zoom) {
            handleResetZoom(pages[newpageID]);
        }
    }

    const handleChangeTakeoffSettings = (settings) => {
        setTakeoffSettings({ ...settings });

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractortakeoffprojectsettings/${takeoffSettings?.id}/`,
            data: settings,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleChangeFilter = (type, value) => {
        if (project[type] !== value) {
            setChangingFilter(true);
            setCurrentMeasurement(null);

            setProject(prev => {
                let temp = { ...prev };
                temp[type] = value;
                return temp;
            })

            axios({
                'method': 'post',
                'url': `${API_ROUTE}/api/takeoff/`,
                'data': {
                    'projectID': project.id,
                    'pageID': pageID,
                    'type': type,
                    'value': value,
                },
                'headers': {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    console.log(res);

                    setChangingFilter(false);
                    if (Object.keys(measurements).length > 250 || Object.keys(res.data.measurements).length > 250) {
                        //reload the page
                        //window.location.reload();
                        setMeasurements(res.data.measurements);
                        setTree(res.data.tree);

                    } else {
                        //setProject(res.data.project);
                        setMeasurements(res.data.measurements);
                        //setGroups(res.data.groups);
                        setTree(res.data.tree);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const handleConvertLength = (measurement) => {
        if (measurement.type === 'polygon') {
            let newLine = measurement.polygon_dots.map((dot) => ({
                x: dot.x,
                y: dot.y,
                arc: dot.arc,
            })).concat([{
                x: measurement.polygon_dots[0].x,
                y: measurement.polygon_dots[0].y,
                arc: measurement.polygon_dots[0].arc,
            }])

            handleLineTransform({
                ...measurement,
                type: "line",
                line_dots: newLine,
                size: 2,
            }, true, null);
        } else if (measurement.type === 'rectangle') {
            console.log(measurement);

            let newLine = [
                {
                    x: measurement.rectangle.x,
                    y: measurement.rectangle.y,
                    arc: false,
                },
                {
                    x: measurement.rectangle.x + measurement.width,
                    y: measurement.rectangle.y,
                    arc: false,
                },
                {
                    x: measurement.rectangle.x + measurement.width,
                    y: measurement.rectangle.y + measurement.height,
                    arc: false,
                },
                {
                    x: measurement.rectangle.x,
                    y: measurement.rectangle.y + measurement.height,
                    arc: false,
                },
                {
                    x: measurement.rectangle.x,
                    y: measurement.rectangle.y,
                    arc: false,
                },
            ]

            console.log(newLine);

            handleLineTransform({
                ...measurement,
                type: "line",
                line_dots: newLine,
                size: 2,
            }, true, null);
        }
    }

    const handleConvertPolygon = (measurement) => {
        if (measurement.type === 'rectangle') {
            let newPoly = [
                {
                    x: measurement.rectangle.x,
                    y: measurement.rectangle.y,
                },
                {
                    x: measurement.rectangle.x + measurement.width,
                    y: measurement.rectangle.y,
                },
                {
                    x: measurement.rectangle.x + measurement.width,
                    y: measurement.rectangle.y + measurement.height,
                },
                {
                    x: measurement.rectangle.x,
                    y: measurement.rectangle.y + measurement.height,
                },
            ]

            handlePolygonTransform({
                ...measurement,
                type: "polygon",
                polygon_dots: newPoly,
                sub_polygons: [],
            }, null, true);
        }
    }

    const handleTurnOffDrawing = () => {
        let tempHintingAutoMeasure = { ...hintingAutoMeasure };
        let tempHintingAutoMeasurePerim = { ...hintingAutoMeasurePerim };
        let tempAIAutoCountRect = { ...AIAutoCountRect };
        let tempAIAutoCountPoly = { ...AIAutoCountPoly };
        let tempAIAutoCountExample = { ...AIAutoCountExample };
        let tempHintingAutoFindDW = { ...hintingAutoFindDW };
        let tempAIAutoFinding = { ...AIAutoFinding };
        let tempAIAutoMeasureLengthRect = { ...AIAutoMeasureLengthRect };

        Object.keys(pages).forEach((key) => {
            if (tempHintingAutoMeasure[key]) tempHintingAutoMeasure[key].show = false;
            if (tempHintingAutoMeasurePerim[key]) tempHintingAutoMeasurePerim[key].show = false;
            if (tempAIAutoCountRect[key]) tempAIAutoCountRect[key].show = false;
            if (tempAIAutoCountPoly[key]) tempAIAutoCountPoly[key].show = false;
            if (tempAIAutoCountExample[key]) tempAIAutoCountExample[key].show = false;
            if (tempHintingAutoFindDW[key]) tempHintingAutoFindDW[key].show = false;
            if (tempAIAutoFinding[key]) tempAIAutoFinding[key].show = false;
            if (tempAIAutoMeasureLengthRect[key]) tempAIAutoMeasureLengthRect[key].show = false;
        })

        setHintingAutoMeasure(tempHintingAutoMeasure);
        setHintingAutoMeasurePerim(tempHintingAutoMeasurePerim);
        setAIAutoCountRect(tempAIAutoCountRect);
        setAIAutoCountPoly(tempAIAutoCountPoly);
        setAIAutoCountExample(tempAIAutoCountExample);
        setHintingAutoFindDW(tempHintingAutoFindDW);
        setAIAutoFinding(tempAIAutoFinding);
        setAIAutoMeasureLengthRect(tempAIAutoMeasureLengthRect);
        setSelectingLegend(false);
        setDrawingLegend(false);
        setDrawingLegendEntry(false);
        setDrawingLegendEntryRect({
            'start': null,
            'end': null,
        })
        setHintingSAM(false);
        setHintingLength(false);

        setPopulatingLegend(prev => ({
            ...prev,
            'message': '',
            'progress': 0,
            'show': false,
            'running': false,
            'id': null,
        }))

        setDrawingCircle(false);
        setDrawingRectangle(false);
        setDrawingPolygon(false);
        setCuttingPolygonRect(false);
        setCuttingRectangleRect(false);
        setCuttingPolygon(false);
        setCuttingRectangle(false);
        setSplittingPolygon(false);
        setSplittingRectangle(false);
        setDrawingLine(false);
        setDrawingCount(false);
        setAddingCount(false);
        setDeletingCount(false);
        setErasingCount(false);
        setSelectionCalculator(false);
        setDrawingScale(false);

        setDrawingAnnotationArrow(false);
        setDrawingAnnotationRectangle(false);
        setDrawingAnnotationText(false);
    }

    const handleUpdateAnnotation = (annotation) => {
        setAnnotations(prev => ({
            ...prev,
            [annotation.id]: annotation,
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/annotation/`,
            data: annotation,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleDeleteAnnotations = () => {
        setAnnotations(prev => {
            let newAnnotations = { ...prev };
            selectedAnnotations.forEach((id) => {
                if (!newAnnotations[id]) return
                delete newAnnotations[id];
            })
            return newAnnotations;
        })

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/annotation/`,
            data: {
                'ids': [...new Set(selectedAnnotations)],
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })

        setSelectedAnnotations([]);
        setCurrentAnnotation(null);
    }

    const handleDeleteAnnotation = (annotationID) => {
        setAnnotations(prev => {
            let newAnnotations = { ...prev };
            delete newAnnotations[annotationID];
            return newAnnotations;
        })

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/annotations/${annotationID}/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })

        setCurrentAnnotation(null);
    }

    const handleCreateAnnotation = ({ type, data }) => {
        if (type === 'text') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/annotation/`,
                data: {
                    'author': auth.contractor.id,
                    'page': pageID,
                    'type': 'text',
                    'text': data.text || 'Text',
                    'x': data.x,
                    'y': data.y,
                    'width': data.width,
                    'height': data.height,
                    'size': data.size || 100,
                    'font_family': 'Arial',
                    'font_style': 'normal',
                    'fill_color': data.fill_color || '#9DD9F3',
                    'stroke_color': data.stroke_color || '#006aff',
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setAnnotations(prev => ({
                        ...prev,
                        [response.data.id]: response.data,
                    }))
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (type === 'arrow') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/annotation/`,
                data: {
                    'author': auth.contractor.id,
                    'page': pageID,
                    'type': 'arrow',
                    'text': 'Text',
                    'x1': data.x1,
                    'y1': data.y1,
                    'x2': data.x2,
                    'y2': data.y2,
                    'size': data.size || 10,
                    'stroke_color': data.stroke_color || '#006aff',
                    'fill_color': data.fill_color || '#9DD9F3',
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setAnnotations(prev => ({
                        ...prev,
                        [response.data.id]: response.data,
                    }))
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (type === 'rectangle') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/annotation/`,
                data: {
                    'author': auth.contractor.id,
                    'page': pageID,
                    'type': 'rectangle',
                    'text': 'Text',
                    'x': data.x,
                    'y': data.y,
                    'width': data.width,
                    'height': data.height,
                    'size': data.size || 10,
                    'stroke_color': data.stroke_color || '#006aff',
                    'fill_color': data.fill_color || '#9DD9F3',
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log(response);

                    setAnnotations(prev => ({
                        ...prev,
                        [response.data.id]: response.data,
                    }))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    if (project && !auth?.contractor?.bobyard_admin && auth?.contractor?.company !== project?.company) {
        return (
            <NotFoundPage />
        )
    }

    if (!project) {
        return (
            <TakeoffLoading />
        )
    }

    return (
        <TakeoffContext.Provider
            value={{
                stageRef,

                project, setProject,
                handleUpdateProject,

                pageID,
                pages, setPages,
                groups, setGroups,

                tree, setTree,

                measurements, setMeasurements,

                pageGroups, setPageGroups,
                ungroupedPages, setUngroupedPages,

                globalHide, setGlobalHide,

                search, setSearch,

                currentGroup, setCurrentGroup,

                previewURL, setPreviewURL,

                currentDot, setCurrentDot,
                currentMeasurement, setCurrentMeasurement,

                selecting, setSelecting,
                selectedMeasurements, setSelectedMeasurements,
                selectedDots, setSelectedDots,
                selectedGroups, setSelectedGroups,

                handleClickMeasurement,
                handleClickGroup,

                draggingPage, setDraggingPage,
                scrollingPage, setScrollingPage,

                drawingCount, drawingLine, drawingPolygon, drawingRectangle, drawingCircle,
                setDrawingLine, setDrawingRectangle, setDrawingPolygon, setDrawingCount, setDrawingCircle,

                drawing: drawingScale || drawingRectangle || drawingPolygon || drawingCount || drawingLine || drawingCircle || addingCount || deletingCount || cuttingPolygon || cuttingRectangle || splittingPolygon || splittingRectangle || cuttingPolygonRect || cuttingRectangleRect,

                drawingScale, setDrawingScale,

                addingCount, setAddingCount,
                deletingCount, setDeletingCount,
                erasingCount, setErasingCount,

                selectionCalculator, setSelectionCalculator,
                selectedCalculatedTotals, setSelectedCalculatedTotals,

                cuttingPolygonRect, setCuttingPolygonRect,
                cuttingRectangleRect, setCuttingRectangleRect,

                cuttingPolygon, setCuttingPolygon,
                cuttingRectangle, setCuttingRectangle,

                splittingPolygon, setSplittingPolygon,
                splittingRectangle, setSplittingRectangle,

                editingMeasurement, setEditingMeasurement,

                groupMeasurement, DeleteMeasurement, createMeasurement, createMeasurements,

                handleRectTransform, handlePolygonTransform, handleLineTransform, handleCountTransform, handleCircleTransform,
                handleDuplicateMeasurement,
                handleDuplicateGroup,
                updateMeasurementInState,

                handleZoom, handleResetZoom, handleZoomToMeasurement, handleZoomToDot, handleZoomToAnnotation,

                showDetails, setShowDetails,
                showSelectedTotal, setShowSelectedTotal,
                showMiniMap, setShowMiniMap,

                handleCombineMeasurements,
                handleConvertPolygon,
                handleConvertLength,

                history, setHistory,
                currentHistory, setCurrentHistory,
                handleUndo, handleRedo,
                changingHistory, setChangingHistory,

                changingFilter, setChangingFilter,

                takeoffSettings, setTakeoffSettings,
                handleChangeTakeoffSettings,

                keybinds, setKeybinds,

                optionDown, setOptionDown,
                controlDown, setControlDown,
                shiftDown, setShiftDown,
                tabDown, setTabDown,
                metaDown, setMetaDown,

                settings,

                handleNewPage,
                handleUnincludePage,

                handleChangeFilter,

                showDeleteMeasurementModal, setShowDeleteMeasurementModal,
                showDeleteGroupModal, setShowDeleteGroupModal,
                deleteGroup, setDeleteGroup,

                AILegendViews, setAILegendViews,
                showLegendsPopup, setShowLegendsPopup,
                AILegends, setAILegends,
                selectingLegend, setSelectingLegend,
                drawingLegend, setDrawingLegend,
                drawingLegendEntry, setDrawingLegendEntry,
                drawingLegendEntryRect, setDrawingLegendEntryRect,
                localizationLegends, setLocalizationLegends,
                currentLegend, setCurrentLegend,
                populatingLegend, setPopulatingLegend,

                autoCountReference, setAutoCountReference,
                autoCountLocation, setAutoCountLocation,
                AIAutoCountExample, setAIAutoCountExample,

                hintingSAM, setHintingSAM,
                hintingAutoMeasure, setHintingAutoMeasure,
                hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
                hintingAutoFindDW, setHintingAutoFindDW,

                hintingLength, setHintingLength,
                inferencingLength, setInferencingLength,

                AIAutoClassifying, setAIAutoClassifying,
                AILocalization, setAILocalization,
                AIAutoFinding, setAIAutoFinding,
                SAMInferencing, setSAMInferencing,

                AIAutoCountRect, setAIAutoCountRect,
                AIAutoCountPoly, setAIAutoCountPoly,

                AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,

                AIMessages, setAIMessages,
                autoNameAllPages, setAutoNameAllPages,

                handleTurnOffDrawing,

                stopWatch,

                annotations, setAnnotations,
                selectedAnnotations, setSelectedAnnotations,
                handleCreateAnnotation,
                handleUpdateAnnotation,
                handleDeleteAnnotation,
                handleDeleteAnnotations,
                currentAnnotation, setCurrentAnnotation,
                drawingAnnotationArrow, setDrawingAnnotationArrow,
                drawingAnnotationRectangle, setDrawingAnnotationRectangle,
                drawingAnnotationText, setDrawingAnnotationText,
                showDeleteAnnotationModal, setShowDeleteAnnotationModal,
            }}
        >
            <TakeoffNavbar
                auth={auth}
                projectUUID={projectUUID}
            />

            <ToastContainer
                className={'takeoff-toastcontainer'}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                closeButton={CloseButton}
                onClose={(e) => console.log('closed', e)}
            />

            {showLegendsPopup &&
                <LegendsPopup />
            }

            <div className='takeoff-container'>
                {pages?.[pageID]?.file &&
                    <TakeoffPlan
                        url={pages[pageID].file}
                    >
                        <RenderMeasurements />

                        <RenderAnnotations />

                        {drawingCircle &&
                            <CircleConstructor />
                        }

                        {drawingRectangle &&
                            <RectangleConstructor />
                        }

                        {drawingPolygon &&
                            <PolygonConstructor />
                        }

                        {cuttingPolygonRect &&
                            <PolygonCutterRect />
                        }

                        {cuttingRectangleRect &&
                            <RectangleCutterRect />
                        }

                        {cuttingPolygon &&
                            <PolygonCutter />
                        }

                        {cuttingRectangle &&
                            <RectangleCutter />
                        }

                        {drawingLine &&
                            <LineConstructor />
                        }

                        {splittingPolygon &&
                            <PolygonSpliter />
                        }

                        {splittingRectangle &&
                            <RectangleSpliter />
                        }

                        {drawingCount &&
                            <CountConstructor
                                countDots={countDots}
                                setCountDots={setCountDots}
                                countHistory={countHistory}
                                setCountHistory={setCountHistory}
                            />
                        }

                        {erasingCount &&
                            <CountEraser />
                        }

                        {selectionCalculator &&
                            <SelectionCalculator />
                        }

                        {drawingScale &&
                            <ScaleConstructor />
                        }

                        {AIAutoCountExample?.[pageID]?.show &&
                            <AICountExample />
                        }

                        {AIAutoCountRect?.[pageID]?.show &&
                            <AICountRectangle />
                        }

                        {AIAutoCountPoly?.[pageID]?.show &&
                            <AICountPolygon />
                        }

                        {AIAutoMeasureLengthRect?.[pageID]?.show &&
                            <AIMeasureLengthRectangle />
                        }

                        {hintingAutoMeasure?.[pageID]?.show &&
                            <AIAutoMeasureRoomsRectangle />
                        }

                        {hintingAutoMeasurePerim?.[pageID]?.show &&
                            <AIAutoMeasureRoomsPerimRectangle />
                        }

                        {hintingAutoFindDW?.[pageID]?.show &&
                            <AIAutoFindDWRectangle />
                        }

                        {AIAutoClassifying && AILocalization &&
                            <AILocalizationDots />
                        }

                        {AIAutoFinding?.[pageID]?.show && AILocalization &&
                            <AIAutoFind />
                        }

                        {hintingSAM &&
                            <SAMHint projectUUID={projectUUID} />
                        }

                        {hintingLength &&
                            <AILengthHint projectUUID={projectUUID} />
                        }

                        {selectingLegend &&
                            <AILegendSelector />
                        }

                        {drawingLegend &&
                            <AILegendConstructor />
                        }

                        {drawingLegendEntry &&
                            <AILegendEntry />
                        }

                        {drawingAnnotationArrow &&
                            <AnnotationArrowConstructor />
                        }

                        {drawingAnnotationRectangle &&
                            <AnnotationRectangleConstructor />
                        }

                        {drawingAnnotationText &&
                            <AnnotationTextConstructor />
                        }
                    </TakeoffPlan>
                }

                {pages && tree &&
                    <TakeoffSidebar />
                }

                <DetailsNavbar />

                {project?.toolbar_position &&
                    <TakeoffToolbar />
                }

                {selectedMeasurements?.filter((m, i, a) => a.indexOf(m) === i).length > 1 &&
                    <SelectedTotals />
                }

                {selectionCalculator &&
                    <SelectedCalculatedTotals />
                }

                {showDeleteGroupModal &&
                    <DeleteGroupModal
                        showDeleteGroupModal={showDeleteGroupModal}
                        setShowDeleteGroupModal={setShowDeleteGroupModal}
                    />
                }

                {showDeleteMeasurementModal &&
                    <DeleteMeasurementModal
                        showDeleteMeasurementModal={showDeleteMeasurementModal}
                        setShowDeleteMeasurementModal={setShowDeleteMeasurementModal}
                        handleDelete={() => {
                            if (new Set(selectedMeasurements).size > 1) {
                                DeleteMeasurements();
                            } else {
                                DeleteMeasurement(currentMeasurement ? measurements[currentMeasurement] : measurements[selectedMeasurements[0]]);
                            }
                            setShowDeleteMeasurementModal(false);
                            setCurrentMeasurement(null);
                        }}
                    />
                }

                {showDeleteAnnotationModal &&
                    <DeleteAnnotationModal />
                }

                {pages?.[pageID]?.file &&
                    <MiniMap
                        url={pages[pageID].file}
                    />
                }

                <ToolGuide />
            </div>
        </TakeoffContext.Provider>
    );
}

function CloseButton({ closeToast }) {
    const handleClick = (e) => {
        console.log(e);

        closeToast(e);
    }

    return (
        <i
            className="takeoff-toast-close-icon"
            onClick={handleClick}
        >
            &#x2715;
        </i>
    )
}
